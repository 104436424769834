import _, { cloneDeep } from 'lodash';
import { getActivitiesShareDetails, messageAlert, modifyReciepientsResponse, modifyCollaboration, groupRelatedProjectsByRole } from '../../utils';
import getAxios from '../../utils/baseApi';
import { getEventTypeFilter } from '../../utils/commonFunctions';
import { handleError } from '../../utils/errorHandler';
import { EVENTS_FIELD_QUERY, PEOPLE_FIELD_QUERY, PROJECT_FIELD_QUERY, PROJECT_SEARCH_FIELDS, ROLES_FIELD_QUERY, USER_FIELD_QUERY, COMPANY_FIELD_QUERY, EVENTS_COMPANY_FIELD_QUERY, COMPANY_SEARCH_FIELDS, COMPANY_GROUP_PROJECT_FIELDS, PEOPLE_FIELDS, ROLES_SEARCH_FIELDS, MIN_PROJECTS_REQUIRED_FOR_DISPLAY } from '../../utils/settings';
import {
    collabList, companiesForPeopleWorkingOnProject, fetchDetailsPage, isNoCollab, isProjectFramework, noMoreCollaboration,
    notesDetailForAllType, notesDetailForObjectId, peopleRolesCompaniesCount, peopleWorkingOnRolesCompanies, resetIncludeExcludeIds, rolesForProject,
    shareForAllEntity, tagsDetailForAllObjectType, tagsDetailForObjectId, updateAssociateFramework, updateEventInDetails, updateIsAssociateFrameworkShowMore,
    updatePrimaryFramework, updateProcessId, updateTabDetails, userActivityCountData, shareRecipientsDetail, updateCompaniesRoles, updateGroupCompaniesDetails, updateCompanyProjectRoles,
    updateProjectDataForRoleId, updatePeopleOnOtherProject, updateOtherOrgRoles, updateCompanyRelatedRoles, updateCompanyRelatedCompanies, updateWorksWithCompanies,
    updateWorksWithCompaniesProjects, updateJointProjects, updatePeopleProjectRoles, updatePeopleProjectDataForRoleId, updateTeams, updateLicences, fetchNoDetailsPage,
    fetchShowMoreListForWorkWithCompaniesProjects, updateIfMoreProjectDataForRoleIdAvailable, updateIfShowMoreForGroupCompaniesAvailable, fetchShowMoreListForGC, updateIfMoreCompanyRelatedCompaniesAvailable, updateRelationshipAnyRoleIdsSecondList, updateRelationshipAnyRoleIdsFirsList, updateIfMoreJointProjectsAvailable
} from './redux/reducer';
import { shareInformationDetails, recipientDetails, teamDetails } from '../../redux/features/user/userslice';
import { setDataForProjectDetails, notesDataFormatter, setProjectDataForCalendarEvents, rolesPostProcessing, addClients, teamsUsersDataMergeProcessing, setDataForCompanyDetails, setCompanyDataForCalendarEvents, setDataForPeopleDetails } from './postprocessing';
import { updateCompanyDetail, updateIfMoreResultAvailable, updatelistOfSelectedObjectIdsFav, updateObjectData, updatePeopleProjectsList, updateProjectsFavForCompanyId, updateSearchListFav, updateGroupCompanyProjects, updateGroupCompanyProjectsShowMore, updateGroupCompanyProjectsFav } from '../search/redux/reducer';
import { fetchBadgeCount } from '../header/apiclient';
import { updateSearchList, updateNoResultNotesTextSearch, updateNotesCount, updateNotesList, updateShowMoreNotesList, updateIsAllNotesArchive, updateSearchListForUpdates } from '../myleads/reducer';
import { updateSearchlist } from '../leaguetable/reducer';
import { fetchCalendarEvents } from '../calendar/apiclient';
import { fetchTagLists } from '../myleads/apiclient';
import { updateCalendarOffsetLimitValue } from '../calendar/reducer';

var axios = getAxios()

export const fetchDataForDetailsPage = async (id, dispatch, isPrint, type) => {
    let detailType = type === 'project' ? 'projects' : type === "company" ? "companies" : "people"
    let fields = type === 'project' ? PROJECT_FIELD_QUERY : type === "company" ? COMPANY_FIELD_QUERY : PEOPLE_FIELDS
    await axios.get(`${detailType}/${id}` + '?fields=' + fields).then((response) => {
        if (response.status === 200) {
            if (type === 'company') {
                axios.get(`${detailType}/${id}/people?fields=${PEOPLE_FIELD_QUERY}&ignore_customer_profile=true&offset=0&limit=10000&sort=person_first_name,person_last_name,person_id,-person_added`).then((response) => {
                    if (response.status === 200) {
                        dispatch(updatePeopleOnOtherProject(response.data.people))
                    }
                    else {
                        dispatch(updatePeopleOnOtherProject([]))
                    }
                })
                axios.get(`${detailType}/${id}` + '/people?management_team=true&offset=0&limit=10000').then((response) => {
                    if (response.status === 200) {
                        dispatch(updateOtherOrgRoles(response.data.people))
                    }
                    else {
                        dispatch(updateOtherOrgRoles([]))
                    }
                })
            }
            let objectData = type === 'project' ? setDataForProjectDetails(response.data.projects) : type === "company" ? setDataForCompanyDetails(response.data.companies) : setDataForPeopleDetails(response.data.people[0])
            dispatch(fetchDetailsPage(objectData))
            if (response.data && response.data.projects && response.data.projects.project_framework_description) {
                dispatch(isProjectFramework(true))
            }
            fetchTeams(dispatch)
            fetchLicences(dispatch)

            if (type === 'project') {
                window.Intercom('trackEvent', 'View project', {
                    'project_id': id,
                })
            }
            if (type === 'company') {
                window.Intercom('trackEvent', 'View company', {
                    'company_id': id,
                })
            }
            if (type === 'person') {
                window.Intercom('trackEvent', 'View person', {
                    'person_id': id,
                })
            }
        } else if (response.status === 204) {
            dispatch(fetchNoDetailsPage(true))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchDetailUpdatesData = (projectId, type, dispatch) => {
    let detailType = type === 'project' ? 'projects' : 'companies'
    axios.get(`${detailType}/${projectId}/updates?sort=-updated_on&offset=0&limit=1000`).then((response) => {
        if (response.status === 200) {
            dispatch(updateTabDetails(response.data.updates))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const updateArchive = (payload, dispatch, state, settings, userState) => {
    let detailType = payload.objectType === 'project' ? 'projects' : 'companies'
    var jsonData = JSON.stringify({ 'archive': payload.archive });
    axios.put(`${detailType}/${payload.objectId}`, jsonData)
        .then(function (response) {
            if (response.status === 201) {
                let clonedObj = { ...state.detailsPage }
                clonedObj = { ...clonedObj, archive: payload.archive }
                dispatch(fetchDetailsPage(clonedObj))
                let collabPayload = {
                    id: payload.objectId,
                    type: payload.objectType,
                    offset: 0
                }
                fetchAllCollabs(collabPayload, dispatch, state, settings, userState)
                const message = payload.archive ? 'Project archived successfully' : 'Project unarchived successfully'
                messageAlert(dispatch, message, 'success')
            }
        }).catch((error) => {
            handleError(error, dispatch)
        })
}

export const sendFeedback = (params, dispatch) => {
    axios.post('/feedback', JSON.stringify(params))
        .then(function (response) {
            messageAlert(dispatch, response.data.message, 'success')
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const favourite = (entity, dispatch, state, userState, listFav, settings, roleId, sort, search, prevNextList) => {
    let detailType = entity.objectType === 'project' ? 'projects' : 'companies'
    var jsonData = JSON.stringify({ 'favourite': entity.favourite });
    axios.put(`${detailType}/${entity.objectId}`, jsonData)
        .then(function (response) {
            if (response.status === 201) {
                let message = entity.favourite ? `Selected ${detailType} have been favourited` : `Selected ${detailType} have been unfavourited`
                if (listFav === 'searchListFav') {
                    updateSearchLists(entity, state, dispatch);
                } else if (listFav === 'myLeadsFav') {
                    updateMyleadsSearchList(entity, state, dispatch, prevNextList);
                } else if (listFav === 'leagueTableFav') {
                    updateMyleadsSearchList(entity, state, dispatch, listFav, search);
                } else {
                    updateDetails(state, entity, dispatch, roleId, sort, settings, search, userState);
                }
                if (entity.objectType === 'project') {
                    window.Intercom('trackEvent', 'Favourite project', {
                        'project_id': entity.objectId
                    })
                } else {
                    window.Intercom('trackEvent', 'Favourite company', {
                        'company_id': entity.objectId
                    })
                }
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const fetchLabelData = (id, type, dispatch, tagId) => {
    let detailType = type === 'project' ? 'projects' : 'companies'
    let key = type === 'project' ? 'project_id' : 'company_id'
    let payload = {}
    axios.get(`${detailType}/${id}/tags`).then((response) => {
        if (response.status === 200) {
            dispatch(tagsDetailForObjectId(response.data.tags))
            if (tagId !== undefined) {
                let tagName = []
                _.map(tagId, tagNo => {
                    _.map(response.data.tags, tag => {
                        if (tagNo === tag.tag_id) {
                            tagName.push(tag.tag_name)
                        }
                    })
                })
                if (tagName.length > 0) {
                    payload[key] = id
                    payload['tag_name'] = tagName.toString()
                    window.Intercom('trackEvent', `Tag ${type}`, payload)
                }
            }
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const addTags = (jsonData, dispatch) => {
    let detailType = jsonData.type === 'project' ? 'projects' : 'companies'
    let payload = {};
    payload = createLinkTagPayload(jsonData, payload, detailType, dispatch)
    if (!_.isEmpty(payload)) {
        axios.post(`${detailType}/tags`, JSON.stringify(payload)).then((response) => {
            if (response.status === 201 || response.status === 200) {
                fetchLabelDataForAllDetails(dispatch)
                payload.tag_id !== undefined ? fetchLabelData(jsonData.id, jsonData.type, dispatch, payload.tag_id)
                    : fetchLabelData(jsonData.id, jsonData.type, dispatch)
                fetchUserDetailsActivity(jsonData.id, jsonData.type, dispatch)
                messageAlert(dispatch, 'Tag added successfully', 'success')
                if (payload.tag_name) {
                    if (jsonData.type === 'project') {
                        window.Intercom('trackEvent', 'Tag project', {
                            'project_id': jsonData.id,
                            'tag_name': payload.tag_name.toString()
                        })
                    }
                    if (jsonData.type === 'company') {
                        window.Intercom('trackEvent', 'Tag company', {
                            'company_id': jsonData.id,
                            'tag_name': payload.tag_name.toString()
                        })
                    }
                }
            }
        }).catch((error) => {
            handleError(error, dispatch)
        })
    }
}

export const unLinkTags = (jsonData, dispatch) => {
    let detailType = jsonData.type === 'project' ? 'projects' : 'companies'
    let payload = { "tag_name": jsonData.description[0] }
    axios.delete(`${detailType}/${jsonData.id}/tags`, { data: JSON.stringify(payload) }).then((response) => {
        if (response.status === 201) {
            fetchLabelDataForAllDetails(dispatch)
            fetchLabelData(jsonData.id, jsonData.type, dispatch)
            fetchUserDetailsActivity(jsonData.id, jsonData.type, dispatch)
            messageAlert(dispatch, 'Tag deleted successfully', 'success')
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchLabelDataForAllDetails = async (dispatch) => {
    await axios.get('/tags').then((response) => {
        if (response.status === 200) {
            dispatch(tagsDetailForAllObjectType(response.data.tags))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchUserDetailsActivity = (id, type, dispatch) => {
    let detailType = type === 'project' ? 'projects' : type === 'company' ? 'companies' : 'tags'
    let query = type === 'project' ? { "project_id": { "operator": "=", "value": id } } : { "company_id": { "operator": "=", "value": id } }
    let fields = USER_FIELD_QUERY
    fields += type === 'project' ? ',project_id' : ',company_id'
    axios.get(`${detailType}?query=${JSON.stringify(query)}&fields=${fields}`).then((response) => {
        if (response.status === 200) {
            let resData = type === 'project' ? response.data.projects[0] : response.data.companies[0]
            dispatch(userActivityCountData(resData))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const addNote = (data, dispatch) => {
    let detailType = data.type === 'project' ? 'projects' : 'companies'
    let jsonData = JSON.stringify({ 'note': data.description })
    axios.post(`/${detailType}/${data.id}/notes`, jsonData).then((response) => {
        if (response.status === 201) {
            fetchUserDetailsActivity(data.id, data.type, dispatch)
            fetchNotesForParticularObjectId(data.id, data.type, dispatch, dispatch)
            messageAlert(dispatch, 'Note added successfully', 'success')
            let payload = {}
            let key = data.type === 'project' ? 'project_id' : 'company_id'
            payload[key] = data.id
            window.Intercom('trackEvent', `Note ${data.type}`, payload)
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchNotesForParticularObjectId = (id, type, dispatch) => {
    let detailType = type === 'project' ? 'projects' : 'companies'
    let maxlength = 100
    let offset = 0
    axios.get(`${detailType}/${id}/notes?offset=${offset}&limit=${maxlength}`).then((response) => {
        if (response.status === 200) {
            let responseData = []
            if (response.data) {
                if (response.data.notes && response.data.notes.length > 0) {
                    responseData = notesDataFormatter(response.data)
                }
            }
            dispatch(notesDetailForObjectId(responseData))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchNotesForAllDetails = (type, myleads, dispatch) => {
    let searchText = myleads?.notesSearchText === '' ? false : true
    let url = `notes?object_type=${type}&offset=${myleads?.offsetLimitValue?.offset}&limit=${myleads?.offsetLimitValue?.records}&fields=${type === 'project' ? 'project_title,archive' : 'company_name'}`
    url = searchText ? url + `&query={"notes_text":{"operator":"=","value":"${myleads?.notesSearchText}"}}` : url
    axios.get(url).then((response) => {
        if (response.status === 200) {
            let responseData = []
            if (response.data) {
                if (response.data.notes && response.data.notes.length > 0) {
                    const { notes, aggregation } = response.data
                    const filteredNotes = notes.filter(item => !item.archive)
                    const updatedNotes = {
                        notes: filteredNotes,
                        aggregation,
                    }
                    responseData = notesDataFormatter(updatedNotes)
                    if (filteredNotes.length === 0 && response.data.notes.length > 0) {
                        dispatch(updateIsAllNotesArchive(true))
                    } else {
                        dispatch(updateIsAllNotesArchive(false))
                    }
                }
            }
            dispatch(updateNoResultNotesTextSearch(searchText && response.data.length === 0 ? true : false))
            if (myleads?.offsetLimitValue?.offset > 0) {
                dispatch(updateShowMoreNotesList(responseData.notes))
            } else {
                dispatch(updateNotesList(responseData.notes))
                dispatch(updateNotesCount(responseData.aggregation.notes_count))
            }
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const editNote = (data, dispatch, searchPersist, myleads) => {
    let detailType = data.object_type === 'project' ? 'projects' : 'companies'
    let jsonData = JSON.stringify({ 'note': data.description })
    axios.put(`/${detailType}/${data.object_id}/notes/${data.id}`, jsonData).then((response) => {
        if (response.status === 200) {
            fetchNotesForParticularObjectId(data.object_id, data.object_type, dispatch, dispatch)
            fetchUserDetailsActivity(data.object_id, data.object_type, dispatch, dispatch)
            fetchNotesForAllDetails(searchPersist.leadsAppliedContext.notes, myleads, dispatch)
            messageAlert(dispatch, 'Note edited successfully', 'success')
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const deleteNote = (note_id, id, type, dispatch, searchPersist, myleads) => {
    let detailType = type === 'project' ? 'projects' : 'companies'
    axios.delete(`${detailType}/${id}/notes/${note_id}`)
        .then(function (response) {
            fetchNotesForParticularObjectId(id, type, dispatch, dispatch)
            fetchUserDetailsActivity(id, type, dispatch, dispatch)
            fetchNotesForAllDetails(searchPersist.leadsAppliedContext.notes, myleads, dispatch)
            messageAlert(dispatch, 'Note deleted successfully', 'success')
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}
export const fetchEvents = async (id, type, dispatch) => {
    let detailType = type === 'project' ? 'projects' : 'companies'
    await axios.get(`${detailType}/${id}/events`)
        .then(function (response) {
            if (response.status === 200) {
                if (response.data === '' || response.data) {
                    if (response.data && response.data.events.length > 0)
                        var project_id_array = []
                    var company_id_array = []
                    var id = ''
                    var events = []
                    _.map(response.data.events, (event) => {
                        id = event.object_id
                        events.push(event)
                        event.object_type == 'project' ? project_id_array.push(id) : company_id_array.push(id)
                    })
                    if (type === "project") {
                        var jsonData = JSON.stringify(project_id_array)
                        axios.get('projects?query={"project_id":{"operator":"=","value": ' + jsonData + '}}&fields=' + EVENTS_FIELD_QUERY)
                            .then(function (response) {
                                if (response.data && response.data.projects && response.data.projects.length) {
                                    events = setProjectDataForCalendarEvents(response.data.projects, events)
                                }
                                let eventsResponse = { object_id: id, object_type: type, events: events }
                                dispatch(updateEventInDetails(eventsResponse))
                            })
                    }
                    else {
                        axios.get('companies?query={"company_id":{"operator":"=","value": ' + JSON.stringify(company_id_array) + '}}&fields=' + EVENTS_COMPANY_FIELD_QUERY)
                            .then(function (response) {
                                if (response.data && response.data.companies && response.data.companies.length) {
                                    events = setCompanyDataForCalendarEvents(response.data.companies, events)
                                }
                                let eventsResponse = { object_id: id, object_type: type, events: events }
                                dispatch(updateEventInDetails(eventsResponse))
                            })

                    }

                }
            }
            else if (response.status === 204) {
                dispatch(updateEventInDetails({ events: [] }))

            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const addEvent = (jsonData, userState, dispatch) => {
    axios.post('/events', jsonData)
        .then(function (response) {
            if (response.status === 201) {
                messageAlert(dispatch, response.data.message, 'success')
                fetchEvents(jsonData.object_id, jsonData.object_type, dispatch, dispatch)
                fetchUserDetailsActivity(jsonData.object_id, jsonData.object_type, dispatch)
                fetchBadgeCount(userState?.user_id, userState?.licenceFlag, dispatch)
                window.Intercom('trackEvent', 'Calendar event')
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const updateEvent = (jsonData, id, userState, dispatch, calendarEventState) => {
    axios.put('/events/' + id, jsonData)
        .then(function (response) {
            messageAlert(dispatch, 'Event updated successfully', 'success')
            if (typeof calendarEventState !== 'undefined') {
                dispatch(updateCalendarOffsetLimitValue({ offset: 0, records: 50 }))
            } else {
                fetchEvents(jsonData.object_id, jsonData.object_type, dispatch, dispatch)
                fetchBadgeCount(userState?.user_id, userState?.licenceFlag, dispatch)
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const deleteEvent = (objectid, type, id, userState, dispatch, calendarEventState) => {
    axios.delete('/events/' + id)
        .then(function (response) {
            if (response.status === 200) {
                if (typeof calendarEventState !== 'undefined') {
                    // fetchCalendarEvents(dispatch)
                    fetchCalendarEvents(calendarEventState, dispatch)
                } else {
                    fetchEvents(objectid, type, dispatch, dispatch)
                    fetchUserDetailsActivity(objectid, type, dispatch)
                    fetchBadgeCount(userState?.user_id, userState?.licenceFlag, dispatch)
                }
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const fetchShareForObjectId = (id, type, dispatch, state, user) => {
    let detailType = type === 'project' ? 'projects' : type === 'company' ? 'companies' : 'tags'
    axios.get(`/${detailType}/${id}/shares`).then((response) => {
        if (response.status === 200 && response.data.shares.length > 0) {
            let usersList = state && state.allRecipientDetails
            let teamList = state && state.teamListDetails;
            let sharedInfo = getActivitiesShareDetails(response.data.shares, teamList, usersList, state)
            let sharedPayload = { objectType: type, sharedInfo: sharedInfo }
            dispatch(shareInformationDetails(sharedPayload))
            if (type !== 'tags') {
                dispatch(shareForAllEntity(response.data))
            }
        } else {
            let sharedPayload = { objectType: type, sharedInfo: [] }
            dispatch(shareInformationDetails(sharedPayload))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const addShare = (id, type, payload, dispatch, state, user, tagName) => {
    let detailType = type === 'project' ? 'projects' : type === 'company' ? 'companies' : 'tags'
    axios.put(`/${detailType}/${id}`, payload).then((response) => {
        if (response.status === 201) {
            fetchShareForObjectId(id, type, dispatch, state, user)
            fetchUserDetailsActivity(id, type, dispatch)
            messageAlert(dispatch, `This ${type === "tags" ? "tag": type} has been shared successfully`, 'success')
            if (type === 'project') {
                window.Intercom('trackEvent', 'Share project', { 'project_id': id })
            } else if (type === 'company') {
                window.Intercom('trackEvent', 'Share company', { 'company_id': id })
            } else {
                window.Intercom('trackEvent', 'Share tag', { 'tag_name': tagName })
            }
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const deleteShare = (id, type, payload, dispatch, state, user) => {
    let detailType = type === 'project' ? 'projects' : type === 'company' ? 'companies' : 'tags'
    let jsonData = JSON.stringify(payload)
    axios.delete(`/${detailType}/${id}`, { data: jsonData }).then((response) => {
        if (response.status === 200) {
            fetchShareForObjectId(id, type, dispatch, state, user)
            if (type !== 'tags') {
                fetchUserDetailsActivity(id, type, dispatch)
            }
            else {
                fetchTagLists(dispatch)
            }
            messageAlert(dispatch, 'Share deleted successfully', 'success')
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchRolesForProjects = (id, dispatch, isProjectFramework) => {
    let url = `/projects/${id}/roles?fields=${ROLES_FIELD_QUERY}`
    url = isProjectFramework ? url + `&ignore_customer_profile=true` : url
    axios.get(url).then((response) => {
        if (response.status === 200) {
            let roleGroups = { roleGroups: [] }
            roleGroups = rolesPostProcessing(roleGroups, response);
            dispatch(rolesForProject(roleGroups))
        } else if (response.status === 204) {
            dispatch(rolesForProject([]))
        }

    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchAllRecipients = (userData, dispatch, state) => {
    axios.get('/users?offset=0&limit=10000').then(async (response) => {
        if (response.status === 200) {
            let users_list = response.data
            let teams_list = state.teams
            let licences_list = state.licences
            let user_data = users_list && users_list.users.filter(user => user.is_active === true)
            let removeLoggedInUser = user_id => user_data && user_data.filter(user => user.user_id !== user_id)
            const http_response = {
                'teams': teams_list,
                'users': { 'users': user_data },
                'licences': licences_list
            }
            let users = modifyReciepientsResponse(http_response);
            dispatch(recipientDetails(users))
            dispatch(shareRecipientsDetail(removeLoggedInUser(userData.user_id)))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchShareTeams = async (dispatch, state) => {
    axios.get('/users').then(function (response) {
        if (response.status === 200) {
            const teamsResponse = teamsUsersDataMergeProcessing(response.data.users, state.teams.teams)
            let payloadObj = {
                payload: teamsResponse,
                loaded: true
            }
            dispatch(teamDetails(payloadObj.payload))
        }
    }).catch(function (error) {
        handleError(error, dispatch)
    })
}

export const pushToChub = (id, dispatch) => {
    axios.post(`/opportunities/projects/${id}?system=constructionhub`).then((response) => {
        if (response.data.hasOwnProperty('message')) {
            messageAlert(dispatch, response.data.message, 'success')
        }
        if (response.data.hasOwnProperty('errorMessage')) {
            messageAlert(dispatch, response.data.errorMessage, 'danger')
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

function createLinkTagPayload(jsonData, payload, detailType, dispatch) {
    let tagName = [], tagId = [];
    let key = jsonData.type === 'project' ? 'project_id' : 'company_id'
    if (jsonData.ids && jsonData.ids.length > 1) {
        if (!jsonData.ids.includes(0)) {
            payload['tag_id'] = jsonData['ids'];
            payload[key] = jsonData['id'];
        }
        else {
            let jsonPayload = {}
            jsonData.ids.map((id, index) => {
                if (id === 0) {
                    tagName.push(jsonData['description'][index]);
                } else {
                    tagId.push(id);
                }
            });
            if (tagName.length > 0) {
                jsonPayload['tag_name'] = tagName;
                jsonPayload[key] = jsonData['id'];
                axios.post(`${detailType}/tags`, JSON.stringify(jsonPayload)).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        fetchLabelDataForAllDetails(dispatch)
                        fetchLabelData(jsonData.id, jsonData.type, dispatch)
                        fetchUserDetailsActivity(jsonData.id, jsonData.type, dispatch)
                        messageAlert(dispatch, 'Tag added successfully', 'success')
                        if (jsonData.type === 'project') {
                            window.Intercom('trackEvent', 'Tag project', {
                                'project_id': jsonData.id,
                                'tag_name': tagName.toString()
                            })
                        }
                        if (jsonData.type === 'company') {
                            window.Intercom('trackEvent', 'Tag company', {
                                'company_id': jsonData.id,
                                'tag_name': tagName.toString()
                            })
                        }
                    }
                });
            }
            if (tagId.length > 0) {
                jsonPayload['tag_id'] = tagId;
                jsonPayload[key] = jsonData['id'];
                delete jsonPayload['tag_name'];
                axios.post(`${detailType}/tags`, JSON.stringify(jsonPayload)).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        fetchLabelDataForAllDetails(dispatch)
                        fetchLabelData(jsonData.id, jsonData.type, dispatch, tagId)
                        fetchUserDetailsActivity(jsonData.id, jsonData.type, dispatch)
                        messageAlert(dispatch, 'Tag added successfully', 'success')
                    }
                });
            }
        }
    }
    else if (jsonData.ids.includes(0)) {
        payload['tag_name'] = jsonData['description'];
        payload[key] = jsonData['id'];
    }
    else {
        payload['tag_id'] = jsonData['ids'];
        payload[key] = jsonData['id'];
    }
    return payload;
}

function updateDetails(state, entity, dispatch, roleId, sort, settings, search, userState) {
    let clonedObj = { ...state.detailsPage };
    clonedObj = entity.objectId === state.detailsPage.objectId ? { ...clonedObj, favourite: entity.favourite } : clonedObj;
    dispatch(fetchDetailsPage(clonedObj));
    let type = entity.objectType === 'project' ? 'project_id' : 'company_id'
    if(state?.userActivityCount!== undefined){
        let clonedObjForActivityCount = { ...state?.userActivityCount };
        clonedObjForActivityCount = entity.objectId === state?.userActivityCount[type] ? { ...clonedObjForActivityCount, favourite: entity.favourite } : clonedObjForActivityCount;
        dispatch(userActivityCountData(clonedObjForActivityCount));
    }
   
    let collabPayload = {
        id: entity.objectId,
        type: entity.objectType,
        offset: 0
    };
    if (entity.prevNextList === 'groupedCompanyProjects' || entity.prevNextList === "companyProjects") {
        let projects = [];
        _.map(cloneDeep(state.projectDataForRoleId), (record) => {
            record.favourite = record.project_id === entity.objectId ? entity.favourite : record.favourite
            projects.push(record)
        })
        dispatch(updateProjectDataForRoleId({ "aggregation": { "project_count": state?.projectDataForRoleIdCount }, "projects": projects }))
    } else if (entity.prevNextList === 'worksWithCompanies') {
        let payload = {
            objectId: entity.companyId,
            ptno: entity.ptno,
            offset: 0,
            records: 50,
            sort: '-max_project_last_published'
        };
        fetchWorksWithCompanies(payload, dispatch);
    } else if (entity.prevNextList === 'relatedCompaniesJointProjects') {
        if (state.isJointProjectViewed) {
            let projects = [];
            _.map(cloneDeep(state.jointProjects["projects"]), (record) => {
                record.favourite = record.project_id === entity.objectId ? entity.favourite : record.favourite
                projects.push(record)
            })
            dispatch(updateJointProjects({ projects: projects, aggregation: state.jointProjects.aggregation }))

        } else {
            let companies = [];
            _.map(cloneDeep(state.companyRelatedCompanies), (record) => {
                record.favourite = record.company_id === entity.objectId ? entity.favourite : record.favourite
                companies.push(record)
            })
            dispatch(updateCompanyRelatedCompanies({ companies: companies, aggregation: state.companyRelatedCompaniesCount }))
        }
    } else if (entity.prevNextList === "projectRoles") {
        fetchRolesForProjects(state.detailsPage.objectId, dispatch, state.isProjectFrameWork);
    } else if (entity.prevNextList === "groupedPeopleProjects") {
        let projects = [];
        _.map(cloneDeep(state.projectDataForPeopleRoleId), (record) => {
            record.favourite = record.project_id === entity.objectId ? entity.favourite : record.favourite
            projects.push(record)
        })
        dispatch(updatePeopleProjectDataForRoleId({ "projects": projects }))
    } else if (entity.objectType === 'project') {
        if (entity && entity.objectTypeByLocation && entity.objectTypeByLocation !== "person") {
            fetchAllCollabs(collabPayload, dispatch, state, settings, userState);
            fetchProjectDetailsFramework({ objectId: state.detailsPage.objectId, offset: 0, limit: 20 }, dispatch);
        }
    } else if (entity.objectType === 'company') {
        fetchAllCollabs(collabPayload, dispatch, state, settings, userState);
        if (entity.prevNextList === 'groupedCompanies') {
            let headOffice = [], subsidiary = [];
            _.map(cloneDeep(state.groupCompanies["Head Office"]), (record) => {
                record.favourite = record.company_id === entity.objectId ? entity.favourite : record.favourite
                headOffice.push(record)
            })
            _.map(cloneDeep(state.groupCompanies["Subsidiaries"]), (record) => {
                record.favourite = record.company_id === entity.objectId ? entity.favourite : record.favourite
                subsidiary.push(record)
            })
            dispatch(updateGroupCompaniesDetails({ "Head Office": headOffice, "Subsidiaries": subsidiary }))
        }
    }
}

function updateSearchLists(entity, state, dispatch) {
    if (entity.objectType === 'project' && state.projectRowData) {
        let clonedObj = { ...state.projectRowData };
        clonedObj = { ...clonedObj, favourite: entity.favourite };
        dispatch(updateObjectData(clonedObj));
        let clonedObjForList = {
            ...state, searchList: state.searchList.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updateSearchListFav(clonedObjForList.searchList));
    } else if (entity.objectType === 'company' && state.projectRowData) {
        fetchRolesForProjects(state.projectRowData.project_id, dispatch);
    } else if (entity.objectType === 'company' && state.companyDetails) {
        let clonedObj = { ...state.companyDetails };
        clonedObj = { ...clonedObj, favourite: entity.favourite };
        dispatch(updateCompanyDetail(clonedObj));
        let clonedObjForList = {
            ...state, searchList: state.searchList.map(record => record.company_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updateSearchListFav(clonedObjForList.searchList));
    } else if (entity.objectType === 'project' && state.projectsForCompanyId && state.projectsForCompanyId.length) {
        let clonedObjForProject = {
            ...state, projectsForCompanyId: state.projectsForCompanyId.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updateProjectsFavForCompanyId(clonedObjForProject.projectsForCompanyId));
    } else if (entity.objectType === 'project' && state.peopleProjectsList && state.peopleProjectsList.length) {
        let clonedObj = {
            ...state, peopleProjectsList: state.peopleProjectsList.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updatePeopleProjectsList(clonedObj.peopleProjectsList));
    } else if (state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.length) {
        let clonedObjForList = entity.objectType === 'project' ? {
            ...state, listOfSelectedObjectIds: state.listOfSelectedObjectIds.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        } : {
            ...state, listOfSelectedObjectIds: state.listOfSelectedObjectIds.map(record => record.company_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updatelistOfSelectedObjectIdsFav(clonedObjForList.listOfSelectedObjectIds));
    } else if (state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.length) {
        let clonedObjForList = entity.objectType === 'project' ? {
            ...state, listOfSelectedObjectIds: state.listOfSelectedObjectIds.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        } : {
            ...state, listOfSelectedObjectIds: state.listOfSelectedObjectIds.map(record => record.company_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updatelistOfSelectedObjectIdsFav(clonedObjForList.listOfSelectedObjectIds));
    } else if (state?.groupCompanyProjects?.length) {
        let clonedObjForList = {
            ...state, groupCompanyProjects: state.groupCompanyProjects.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        }
        dispatch(updateGroupCompanyProjectsFav(clonedObjForList.groupCompanyProjects));
    } else {
        let clonedObjForList = entity.objectType === 'project' ? {
            ...state, searchList: state.searchList.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        } : {
            ...state, searchList: state.searchList.map(record => record.company_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updateSearchListFav(clonedObjForList.searchList));
    }
}

function updateMyleadsSearchList(entity, state, dispatch, listFav, search) {
    if (listFav === 'myLeadsUpdates') {
        let clonedObjForList = entity.objectType === 'project' ? {
            ...state, updatesSearchList: state.updatesSearchList.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        } : {
            ...state, updatesSearchList: state.updatesSearchList.map(record => record.company_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updateSearchListForUpdates(clonedObjForList.updatesSearchList));
    } else {
        let clonedObjForList = entity.objectType === 'project' ? {
            ...state, searchList: state.searchList.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        } : {
            ...state, searchList: state.searchList.map(record => record.company_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };

        if (listFav === 'leagueTableFav') {
            if (entity.objectType === 'company' && search.companyDetails) {
                let clonedObj = { ...search.companyDetails };
                clonedObj = { ...clonedObj, favourite: entity.favourite };
                dispatch(updateCompanyDetail(clonedObj));
                let clonedObjForList = {
                    ...state, searchList: state.searchList.map(record => record.company_id === entity.objectId ?
                        { ...record, favourite: entity.favourite } : record)
                };
                // dispatch(updateSearchListFav(clonedObjForList.searchList));
                dispatch(updateSearchlist(clonedObjForList.searchList));
            }
            dispatch(updateSearchlist(clonedObjForList.searchList));
        } else {
            dispatch(updateSearchList(clonedObjForList.searchList));
        }
    }
}

export function fetchAllCollabs(payload, dispatch, state, settings, userState) {
    const object_id = payload.type == 'project' ? "project_id" : "company_id"
    let eventType = getEventTypeFilter(state?.collaborationFilter?.[payload.type]?.checkboxFilter)
    let jsonData = JSON.stringify(eventType)
    axios.get('/user_activities?query={ ' + `"` + object_id + `"` + ': {"operator": "=", "value": ' + payload.id + '}, "event_type":{"operator":"=","value": ' + jsonData + '}}&offset=' + payload.offset + '&records=50')
        .then(function (response) {
            dispatch(noMoreCollaboration(false))
            if (response.status === 200) {
                let usersList = userState?.allRecipientDetails
                let teamList = userState?.teamListDetails;
                let tagsList = state?.labelTagsAllDetail
                if (response.data && response.data !== "") {
                    var eventData = response.data
                    if (settings !== undefined && payload.type == 'project') {
                        if (settings && settings.preferences && !settings.preferences.archiving_enabled) {
                            eventData = response.data.filter(event => event.event_type !== 110)
                            eventData = eventData.filter(event => event.event_type !== 111)
                        }
                    }
                    let data = modifyCollaboration(eventData, usersList, teamList, tagsList)
                    if (payload.offset && payload.offset > 0) {
                        let collabData = state.collabList
                        data = collabData.concat(data)
                        dispatch(collabList(data))
                    } else {
                        dispatch(collabList(data))
                    }
                    if (response.data.length < 50) {
                        dispatch(noMoreCollaboration(true))
                    }
                }
            } else {
                let checkboxFilter = state && state.collaborationFilter && state.collaborationFilter[payload.type] && state.collaborationFilter[payload.type].checkboxFilter
                if (checkboxFilter && Object.keys(checkboxFilter).every(item => checkboxFilter[item].active)) {
                    dispatch(isNoCollab(true))
                } else {
                }
                if (payload.offset == 0) {
                    dispatch(collabList([]))
                }
                dispatch(noMoreCollaboration(true))
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export function fetchPeopleWorkingOnThisProjects(payload, dispatch) {
    axios.get(`people?query={"company_id": {"operator": "=", "value": ${payload.companyId}}, "project_id": {"operator": "=", "value": ${payload.projectId}}}&fields=${PEOPLE_FIELD_QUERY}`)
        .then(function (response) {
            if (response.status === 200) {
                dispatch(peopleWorkingOnRolesCompanies(response.data.people))
                dispatch(companiesForPeopleWorkingOnProject(payload))
            } else if (response.status === 204) {
                dispatch(companiesForPeopleWorkingOnProject(payload))
                dispatch(peopleWorkingOnRolesCompanies([]))
            }
        })
        .catch(function (error) {
            handleError(error)
        })
}

export const updateSelectorFavUnfav = async (type, id, payload, dispatch, roleId, relatedRoleId) => {
    var jsonData = JSON.stringify(payload)
    await axios.put(`${type}`, jsonData)
        .then(function (response) {
            if (response.status === 200) {
                let processIdPayload = {
                    list: payload.list ? payload.list : payload.identifier,
                    action: payload.favourite ? "favourite" : "unfavourite",
                    process_id: response.data.process_id
                }
                if (processIdPayload.process_id != 0) {
                    dispatch(updateProcessId(processIdPayload))
                }
                dispatch(resetIncludeExcludeIds([]))
                let message = payload.favourite ? `Selected ${type} have been favourited` : `Selected ${type} have been unfavourited`
                messageAlert(dispatch, message, 'success')
                selectorFavUnfavCommonFunc(payload, id, dispatch, roleId, relatedRoleId, payload.ignore_customer_profile ? true : false);
            }
            else if (response.status === 201) {
                let message = payload.favourite ? `Selected ${type} have been favourited` : `Selected ${type} have been unfavourited`
                messageAlert(dispatch, message, 'success')
                selectorFavUnfavCommonFunc(payload, id, dispatch, roleId, relatedRoleId, payload.ignore_customer_profile ? true : false);
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const fetchProjectDetailsFramework = async (payload, dispatch) => {
    var query = { "project_id": { "operator": "=", "value": payload.objectId } }
    await axios.get("projects?query=" + JSON.stringify(query) + "&ignore_customer_profile=True&fields=" + PROJECT_SEARCH_FIELDS + ',company_id')
        .then(function (response) {
            if (response.status === 200 && response.data && response.data.projects
                && response.data.projects.length > 0 && response.data.projects[0].project_framework) {
                let primary_response = response.data.projects
                primary_response = addClients(primary_response);
                dispatch(updatePrimaryFramework(primary_response))
                const child_query = {
                    "project_framework": { "operator": "=", "value": primary_response[0].project_framework },
                    "project_is_framework": { "operator": "=", "value": false }
                }
                axios.get("projects?query=" + JSON.stringify(child_query) + "&fields=" + (PROJECT_SEARCH_FIELDS + ",project_framework_project_id,company_id") + "&sort=-project_last_published&offset=" + payload.offset.toString() + "&limit=" + payload.limit.toString())
                    .then(function (response) {
                        if (response.status === 200 && response.data.projects && response.data.projects.length) {
                            let child_response = response.data.projects
                            child_response = addClients(child_response);
                            dispatch(updateAssociateFramework(child_response))
                            if (child_response.length > 0 &&
                                (parseInt(payload.offset) + parseInt(payload.limit) < child_response.length)
                            ) {
                                dispatch(updateIsAssociateFrameworkShowMore(true))
                            } else {
                                dispatch(updateIsAssociateFrameworkShowMore(false))
                            }
                        }
                    })
            }
        }).catch(function (error) {
            handleError(error, dispatch)
        })
}

export const updateSelectorControlTags = (type, id, payload, dispatch, roleId, relatedRoleId) => {
    var jsonData = JSON.stringify(payload)
    axios.post(`${type}/tags`, jsonData)
        .then(function (response) {
            if (response.status === 200 || response.status === 201) {
                selectorTagsCommonFunc(payload, type, dispatch, id, roleId, relatedRoleId);
            }
        })
        .catch((error) => {
            handleError(error, dispatch)
        })
}

export const fetchCompanyDetailRoles = (id, dispatch) => {
    axios.get('projects?query={"company_id":{"operator":"=","value": ' + id + '}}&fields=' + "&aggregate_group=role_code&aggregate_function=count&aggregate_field=project_id")
        .then(function (response) {
            if (response.status === 200) {
                if (response.data && response.data.aggregation && response.data.aggregation.length > 0) {
                    dispatch(updateCompaniesRoles(response.data.aggregation))
                }
                else {
                    dispatch(updateCompaniesRoles([]))
                }
            }
        })
        .catch((error) => {
            handleError(error, dispatch)
        })

}

// Group companies for company details

export const fetchGroupCompanies = (payload, dispatch, detail) => {
    axios.get(`companies?query={"company_group_ids": {"operator": "=", "value": ${payload.id}}}&fields=${COMPANY_SEARCH_FIELDS}&offset=${detail.showMoreOffsetLimit.offset}&limit=${detail.showMoreOffsetLimit.records}&sort=${payload.sort}`)
        .then(function (response) {
            if (response.status === 200) {
                let formattedGroupType = groupRelatedProjectsByRole(response.data.companies, true)

                if (response?.data?.aggregation?.company_count > (parseInt(detail?.showMoreOffsetLimit?.offset) + parseInt(detail?.showMoreOffsetLimit?.records))) {
                    dispatch(updateIfShowMoreForGroupCompaniesAvailable(true))
                } else {
                    dispatch(updateIfShowMoreForGroupCompaniesAvailable(false))
                }
                if (detail?.showMoreOffsetLimit?.offset > 0) {
                    dispatch(fetchShowMoreListForGC(formattedGroupType))
                } else {
                    dispatch(updateGroupCompaniesDetails(formattedGroupType))
                }
            }
            else if (response.status === 204) {
                dispatch(updateGroupCompaniesDetails([]))
                dispatch(updateIfShowMoreForGroupCompaniesAvailable(false))
            }
        })
        .catch(function (error) {
            handleError(error)
        })
}

export const showGroupCompaniesProject = (id, dispatch, details) => {
    let companyId = JSON.stringify(id)
    axios.get(`projects?query={"company_id":{"operator": "=", "value": ${companyId}}}&fields=${COMPANY_GROUP_PROJECT_FIELDS}&offset=${details.showMoreOffsetLimit.offset}&limit=${details.showMoreOffsetLimit.records}&sort=-project_last_published`)
        .then(function (response) {
            if (response.status === 200) {
                let responsedata = response.data
                _.map(responsedata.projects, payload => {
                    setDataForProjectDetails(payload);
                })
                if (details.showMoreOffsetLimit.offset > 0) {
                    dispatch(updateGroupCompanyProjectsShowMore(responsedata.projects))
                    dispatch(updateIfMoreResultAvailable(response.data.aggregation.project_count > (details.showMoreOffsetLimit.offset + details.showMoreOffsetLimit.records) ? true : false))
                } else {
                    dispatch(updateGroupCompanyProjects(responsedata))
                    dispatch(updateIfMoreResultAvailable(response.data.aggregation.project_count > MIN_PROJECTS_REQUIRED_FOR_DISPLAY ? true : false))
                }
            }
        })
        .catch(function (error) {
            handleError(error)
        })
}

export const fetchCompanyProjectRoles = (id, dispatch) => {
    axios.get(`projects?query={"company_id":{"operator": "=","value": ${id}}}&aggregate_group=role_code&aggregate_function=count&aggregate_field=project_id&fields=role_code_display`)
        .then(function (response) {
            if (response.status === 200) {
                let anyRoleProjectsCount = 0, role_code = []
                if (response.data) {
                    response.data.aggregation.map(function (role, index) {
                        anyRoleProjectsCount += role.count
                        role_code.push(role.role_code)
                    })
                    if (anyRoleProjectsCount) {
                        response.data.aggregation.unshift({
                            role_code: 0,
                            role_code_display: "Any Role",
                            count: anyRoleProjectsCount,
                            project_count: anyRoleProjectsCount
                        })
                    } else {
                        response.data.aggregation = []
                    }
                }
                dispatch(updateCompanyProjectRoles(response.data.aggregation))
                dispatch(updateRelationshipAnyRoleIdsFirsList(role_code))
            }
        })
        .catch(function (error) {
            handleError(error)
        })
}

export const fetchCompanyRelatedRoles = (id, roleId, dispatch, details) => {
    let role = roleId === 0 ? JSON.stringify(details.relationshipRoleIdsFirstList) : roleId
    axios.get(`projects?prequery={"company_id":{"operator": "=","value":${id}},"role_code":{"operator":"=","value":${role}}}&query={"company_id":{"operator": "!=","value":${id}}}&aggregate_group=role_code&aggregate_function=count&aggregate_field=company_id&limit=500&offset=0&fields=role_code_display`)
        .then(function (response) {
            if (response.status === 200) {
                let anyRoleProjectsCount = 0, role_code = []
                if (response.data) {
                    response.data.aggregation.map(function (role, index) {
                        anyRoleProjectsCount += role.count
                        role_code.push(role.role_code)
                    })
                    if (anyRoleProjectsCount) {
                        response.data.aggregation.unshift({
                            role_code: 0,
                            role_code_display: "Any Role",
                            count: anyRoleProjectsCount,
                            project_count: anyRoleProjectsCount
                        })
                    } else {
                        response.data.aggregation = []
                    }
                }
                dispatch(updateCompanyRelatedRoles(response.data.aggregation))
                dispatch(updateRelationshipAnyRoleIdsSecondList(role_code))
            }
        })
        .catch(function (error) {
            handleError(error)
        })
}

export const fetchProjectsForRoleId = (query, sort, dispatch, detail) => {
    let data = JSON.stringify(query)
    axios.get(`projects?query=${data}&fields=${ROLES_SEARCH_FIELDS}&offset=${detail.showMoreOffsetLimit.offset}&limit=${detail.showMoreOffsetLimit.records}&sort=${sort}`)
        .then(function (response) {
            if (response.status === 200) {
                if (response?.data?.aggregation?.project_count > (parseInt(detail?.showMoreOffsetLimit?.offset) + parseInt(detail?.showMoreOffsetLimit?.records)) && response?.data?.projects?.length !== 0) {
                    dispatch(updateIfMoreProjectDataForRoleIdAvailable(true))
                } else {
                    dispatch(updateIfMoreProjectDataForRoleIdAvailable(false))
                }

                if (detail?.showMoreOffsetLimit?.offset > 0) {
                    let projects = response.data.projects
                    if (detail?.selectorControlSelectionCount > detail?.projectDataForRoleId?.length) {
                        _.map(projects, (project) => {
                            project.is_selected = true
                        })
                    }
                    let aggregationProjects = { aggregation: response.data.aggregation, projects: [...detail?.projectDataForRoleId, ...projects] }
                    dispatch(updateProjectDataForRoleId(aggregationProjects))
                } else {
                    dispatch(updateProjectDataForRoleId(response.data))
                }
            }
            else {
                dispatch(updateIfMoreProjectDataForRoleIdAvailable(false))
                dispatch(updateProjectDataForRoleId({ projects: [] }))
            }
        })
        .catch(function (error) {
            handleError(error)
        })
}

export const fetchCompanyRelatedCompanies = (id, roleId, relatedRoleId, dispatch, sort, detail) => {
    let role1 = roleId === 0 ? JSON.stringify(detail.relationshipRoleIdsFirstList) : roleId
    let role2 = relatedRoleId === 0 ? JSON.stringify(detail.relationshipRoleIdsSecondList) : relatedRoleId
    axios.get(`projects?prequery={"company_id":{"operator": "=","value":${id}},"role_code":{"operator":"=","value":${role1}}}&query={"company_id":{"operator": "!=","value":${id}},"role_code":{"operator":"=","value":${role2}}}&fields=project_id,company_id,company_name,favourite,read,company_roles,project_title,company_address1,company_address2,company_address3,company_address4,company_postcode,company_phone,company_website,count_project_id,max_project_last_published,role_code,shares_count,tags_count,notes_count,company_phone_tps&distinct_field=company_id&offset=${detail.showMoreOffsetLimit.offset}&limit=${detail.showMoreOffsetLimit.records}&sort=${sort}`)
        .then(function (response) {
            if (response.status === 200) {
                if (response?.data?.aggregation?.company_count > (parseInt(detail?.showMoreOffsetLimit?.offset) + parseInt(detail?.showMoreOffsetLimit?.records))) {
                    dispatch(updateIfMoreCompanyRelatedCompaniesAvailable(true))
                } else {
                    dispatch(updateIfMoreCompanyRelatedCompaniesAvailable(false))
                }

                if (detail?.showMoreOffsetLimit?.offset > 0) {
                    let companies = response.data.companies
                    if (detail?.selectorControlSelectionCount > detail?.companyRelatedCompanies?.length) {
                        _.map(companies, (company) => {
                            company.is_selected = true
                        })
                    }
                    companies = { aggregation: response.data.aggregation, companies: [...detail?.companyRelatedCompanies, ...response.data.companies] }
                    dispatch(updateCompanyRelatedCompanies(companies))
                } else {
                    dispatch(updateCompanyRelatedCompanies(response.data))
                }
            }
            else {
                dispatch(updateIfMoreCompanyRelatedCompaniesAvailable(false))
                dispatch(updateCompanyRelatedCompanies({ companies: [] }))
            }
        })
        .catch(function (error) {
            handleError(error)
        })
}

export const fetchPeopleProjectRoles = (id, dispatch) => {
    axios.get(`projects?query={"person_id":{"operator": "=","value": ${id}}}&aggregate_group=role_code&aggregate_function=count&aggregate_field=project_id&fields=role_code_display`)
        .then(function (response) {
            if (response.status === 200) {
                if (response.data && response.data.aggregation && response.data.aggregation.length > 0) {
                    dispatch(updatePeopleProjectRoles(response.data.aggregation))
                    let payload = {
                        personId: id,
                        roleNo: response.data.aggregation[0].role_code,
                        sort: "-project_last_published",
                        limit: 50,
                        offset: 0
                    }
                    fetchProjectsForPeopleRoleId(payload, dispatch)
                }
                else {
                    dispatch(updatePeopleProjectRoles([]))
                }
            }
        })
}
export const fetchWorksWithCompaniesProjects = (payload, dispatch) => {
    axios.get(`projects?query={"company_id":{"operator": "=", "value":  [${payload.objectId}]}}&fields=project_title,project_id,company_roles_display&sort=-project_last_published&offset=${payload.offset.toString()}&limit=${payload.records.toString()}`)
        .then(function (response) {
            if (response.status === 200) {
                if (payload.offset > 0) {
                    dispatch(fetchShowMoreListForWorkWithCompaniesProjects(response.data))
                }
                else {
                    dispatch(updateWorksWithCompaniesProjects(response.data))
                }
            }
        })
        .catch(function (error) {
            handleError(error)
        })
}


export const fetchProjectsForPeopleRoleId = async (payload, dispatch) => {
    let jsonData = JSON.stringify(payload.roleNo)
    axios.get('projects?query={"person_id": {"operator": "=", "value":' + payload.personId + '}, "role_code": {"operator": "=", "value": ' + jsonData + '}}&fields=' + ROLES_SEARCH_FIELDS + "&offset=" + payload.offset + "&limit=" + payload.limit + "&sort=" + payload.sort).
        then(async (response) => {
            if (response.status === 200) {
                if (response.data && response.data.projects && response.data.projects.length > 0) {
                    dispatch(updatePeopleProjectDataForRoleId(response.data && response.data))
                }
                else {
                    dispatch(updatePeopleProjectDataForRoleId([]))
                }
            }

        })
        .catch((error) => {
            handleError(error, dispatch)
        })
}

export const fetchWorksWithCompanies = (payload, dispatch) => {
    axios.get(`companies?prequery={"company_id":{"operator": "!=", "value": ${payload.objectId}},"project_id":{"operator": "=", "value": ${payload.ptno}}}&fields=company_id,company_name,favourite,read,max_project_last_published,company_roles_display,project_last_published,company_address3,shares_count,tags_count,notes_count,count_project_id,role_desc,any_project_value_estimated,sum_project_value&offset=${payload.offset}&limit=${payload.records}&sort=${payload.sort}`)
        .then(function (response) {
            if (response.status === 200) {
                dispatch(updateWorksWithCompanies(response.data))
            } else if (response.status === 204) {
                dispatch(updateWorksWithCompanies([]))
            }
        })
        .catch(function (error) {
            handleError(error)
        })
}

export const fetchJointProjects = (id, view_company_id, roleId, relatedRoleId, dispatch, detail) => {
    let role1 = roleId === 0 ? JSON.stringify(detail.relationshipRoleIdsFirstList) : roleId
    let role2 = relatedRoleId === 0 ? JSON.stringify(detail.relationshipRoleIdsSecondList) : relatedRoleId
    axios.get(`projects?prequery={"company_id":{"operator":"=","value":${id}},"role_code":{"operator":"=","value":${role1}}}&query={"company_id":{"operator": "=","value":${view_company_id}},"role_code":{"operator":"=","value":${role2}}}&fields=${ROLES_SEARCH_FIELDS}&distinct_field=project_id&offset=${detail.showMoreOffsetLimit.offset}&limit=${detail.showMoreOffsetLimit.records}`)
        .then(function (response) {
            if (response.status === 200) {
                if (response?.data?.aggregation?.project_count > (parseInt(detail?.showMoreOffsetLimit?.offset) + parseInt(detail?.showMoreOffsetLimit?.records)) && response.data.projects?.length === 50) {
                    dispatch(updateIfMoreJointProjectsAvailable(true))
                } else {
                    dispatch(updateIfMoreJointProjectsAvailable(false))
                }

                if (detail?.showMoreOffsetLimit?.offset > 0) {
                    let projects = response.data.projects
                    if (detail?.selectorControlSelectionCount > detail?.jointProjects?.projects?.length) {
                        _.map(projects, (project) => {
                            project.is_selected = true
                        })
                    }
                    let aggregationProjects = { aggregation: response.data.aggregation, projects: [...detail?.jointProjects?.projects, ...projects] }
                    dispatch(updateJointProjects(aggregationProjects))
                } else {
                    dispatch(updateJointProjects(response.data))
                }
            }
        })
        .catch(function (error) {
            handleError(error)
        })
}

export const fetchTeams = (dispatch) => {
    axios.get('/teams').then((response) => {
        if (response.status === 200) {
            dispatch(updateTeams(response.data))
        }
    })
}

export const fetchLicences = (dispatch) => {
    axios.get('/licences').then((response) => {
        if (response.status === 200) {
            dispatch(updateLicences(response.data))
        }
    })
}

function selectorTagsCommonFunc(payload, type, dispatch, id, roleId, relatedRoleId) {
    if (payload.listName == "projectRoles") {
        let message = "Selected " + type + " have been tagged.";
        messageAlert(dispatch, message, 'success');
        let partialProfilePayload = payload.ignore_customer_profile ? true : false;
        fetchRolesForProjects(id, dispatch, partialProfilePayload);
        dispatch(resetIncludeExcludeIds([]));
    }
    else if (payload.listName === 'company_projects_role') {
        var tag_count = payload.tag_id ? payload.tag_id.length : 0
        tag_count += payload.tag_name ? payload.tag_name.length : 0
        let projects = [];
        _.map(cloneDeep(roleId.projectDataForRoleId), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            record.is_selected = false
            projects.push(record)
        })
        dispatch(updateProjectDataForRoleId({ "aggregation": { "project_count": roleId?.projectDataForRoleIdCount }, "projects": projects }))
        dispatch(resetIncludeExcludeIds([]));
        let message = "Selected " + type + " have been tagged.";
        messageAlert(dispatch, message, 'success');
    }
    else if (payload.listName === 'groupCompanies') {
        let message = "Selected " + type + " have been tagged.";
        messageAlert(dispatch, message, 'success');
        var tag_count = payload.tag_id ? payload.tag_id.length : 0
        tag_count += payload.tag_name ? payload.tag_name.length : 0
        let headOffice = [], subsidiary = [];
        _.map(cloneDeep(roleId.groupCompanies["Head Office"]), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            record.is_selected = false
            headOffice.push(record)
        })

        _.map(cloneDeep(roleId.groupCompanies["Subsidiaries"]), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            record.is_selected = false
            subsidiary.push(record)
        })
        dispatch(updateGroupCompaniesDetails({ "Head Office": headOffice, "Subsidiaries": subsidiary }))
        dispatch(resetIncludeExcludeIds([]));
    }
    else if (payload.listName === 'work_with_companies') {
        let message = "Selected " + type + " have been tagged.";
        messageAlert(dispatch, message, 'success');
        fetchWorksWithCompanies(payload, dispatch);
    }
    else if (payload['identifier'] === 'relationships') {
        let message = "Selected " + type + " have been tagged.";
        messageAlert(dispatch, message, 'success');
        var tag_count = payload.tag_id ? payload.tag_id.length : 0
        tag_count += payload.tag_name ? payload.tag_name.length : 0
        let companies = []
        _.map(cloneDeep(roleId.companyRelatedCompanies), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            record.is_selected = false
            companies.push(record)
        })
        dispatch(updateCompanyRelatedCompanies({ companies: companies, aggregation: { company_count: roleId.companyRelatedCompaniesCount } }))
    }
    else if (payload['identifier'] === 'joint_projects') {
        let message = "Selected " + type + " have been tagged.";
        messageAlert(dispatch, message, 'success');
        var tag_count = payload.tag_id ? payload.tag_id.length : 0
        tag_count += payload.tag_name ? payload.tag_name.length : 0
        let projects = []
        _.map(cloneDeep(roleId.jointProjects["projects"]), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            record.is_selected = false
            projects.push(record)
        })
        dispatch(updateJointProjects({ projects: projects, aggregation: roleId.jointProjects.aggregation }))
    }
}

function selectorFavUnfavCommonFunc(payload, id, dispatch, roleId, relatedRoleId, ignore_customer_profile) {
    if (payload['identifier'] === 'company_projects_role') {
        let projects = [];
        _.map(cloneDeep(roleId.projectDataForRoleId), (record) => {
            record.favourite = record.is_selected ? payload.favourite : record.favourite
            record.is_selected = false
            projects.push(record)
        })
        dispatch(updateProjectDataForRoleId({ "aggregation": { "project_count": roleId?.projectDataForRoleIdCount }, "projects": projects }))

    }
    else if (payload['identifier'] === 'work_with_companies') {
        fetchWorksWithCompanies(payload, dispatch);
    }
    else if (payload['identifier'] === 'relationships') {
        let companies = [];
        _.map(cloneDeep(roleId.companyRelatedCompanies), (record) => {
            record.favourite = record.is_selected ? payload.favourite : record.favourite
            record.is_selected = false
            companies.push(record)
        })
        dispatch(updateCompanyRelatedCompanies({ companies: companies, aggregation: { company_count: roleId.companyRelatedCompaniesCount } }))

    }
    else if (payload['identifier'] === 'joint_projects') {
        let projects = [];
        _.map(cloneDeep(roleId.jointProjects["projects"]), (record) => {
            record.favourite = record.is_selected ? payload.favourite : record.favourite
            record.is_selected = false
            projects.push(record)
        })
        dispatch(updateJointProjects({ projects: projects, aggregation: roleId.jointProjects.aggregation }))
    }
    else if (payload['identifier'] === 'group_companies') {
        let headOffice = [], subsidiary = [];
        _.map(cloneDeep(roleId.groupCompanies["Head Office"]), (record) => {
            record.favourite = record.is_selected ? payload.favourite : record.favourite
            record.is_selected = false
            headOffice.push(record)
        })
        _.map(cloneDeep(roleId.groupCompanies["Subsidiaries"]), (record) => {
            record.favourite = record.is_selected ? payload.favourite : record.favourite
            record.is_selected = false
            subsidiary.push(record)
        })
        dispatch(updateGroupCompaniesDetails({ "Head Office": headOffice, "Subsidiaries": subsidiary }))

    }
    else {
        fetchRolesForProjects(id, dispatch, ignore_customer_profile);
    }
}