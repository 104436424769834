import getAxios from "../../utils/baseApi"
import { handleError } from "../../utils/errorHandler";
import { EVENTS_COMPANY_FIELD_QUERY, EVENTS_FIELD_QUERY } from "../../utils/settings";
import { resetDropdown, resetSelectedEvents, updateCalendarEventListCount, updateEventList, updateIsRefine, updateShowMoreEventList } from "./reducer";

var axios = getAxios()

const paramValue = (labelValues) => {
    let value = []
    for (var i = 0; i < labelValues.length; i++) {
        if (labelValues[i].value != undefined || labelValues[i].value != null) {
            value.push(labelValues[i].value)
        }
    }
    return value.join(',')
}

export const fetchCalendarEvents = (calendar, dispatch) => {
    const isRefine = calendar?.isRefine
    let sortOrder = calendar?.selectedSortOrder === "Due date" ? 'duedate' : calendar?.selectedSortOrder === "Start date" ? 'startdate' : calendar?.selectedSortOrder === "Priority" || calendar?.selectedSortOrder === "Created" ? calendar?.selectedSortOrder?.toLowerCase() : null

    let type = null
    let status = null

    if (isRefine) {
        if (calendar?.selectedStatus?.length > 0) {
            status = paramValue(calendar?.selectedStatus)
        }
        if (calendar?.selectedType?.length > 0) {
            type = paramValue(calendar?.selectedType)
        }
    }

    axios.get(`/events?object_type=project&offset=${isRefine ? calendar?.offsetLimitValue?.offset : 0}&limit=${isRefine ? calendar?.offsetLimitValue?.records : 50}${isRefine && status !== null ? `&status=${status}` : ''}${isRefine && type !== null ? `&type=${type}` : ''}&fields=${EVENTS_FIELD_QUERY},${EVENTS_COMPANY_FIELD_QUERY}&sort=${isRefine && sortOrder ? sortOrder : 'startdate'}`).then((response) => {
        if (response.status === 200) {
            if (calendar?.offsetLimitValue?.offset > 0) {
                dispatch(updateShowMoreEventList(response.data.events))
            } else {
                dispatch(updateEventList(response.data.events))
            }
            !isRefine && dispatch(resetDropdown(null))
            dispatch(updateCalendarEventListCount(response.data.aggregation.event_count))
            dispatch(resetSelectedEvents([]))
            dispatch(updateIsRefine(false))
        } else if (response?.status === 204) {
            !isRefine && dispatch(resetDropdown(null))
            dispatch(updateEventList([]))
            dispatch(updateCalendarEventListCount(0))
            dispatch(resetSelectedEvents([]))
            dispatch(updateIsRefine(false))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const deleteCalendarEvent = (jsonData, calendar, dispatch) => {
    let payload = JSON.stringify(jsonData)
    axios.delete('/events', { data: payload })
        .then(function (response) {
            if (response.status === 200) {
                fetchCalendarEvents(calendar, dispatch)
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}