import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { Label, Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';

import SelectProjectsFieldStep from './projectFieldStep/SelectProjectsFieldStep';
import SelectCompaniesFieldStep from './SelectCompaniesFieldStep';
import SelectContactsFieldStep from './SelectContactsFieldStep';
import IntervalSelector from './IntervalSelector';
import { updateExcludeContactsWithNoConfirmedName, updateExcludeContactsWithNoEmail, updateExcludeMailed, updateExportCountQuery } from '../../reducer';
import { appendFieldDisplayNames, extractPayloadForRolesAndMailedExportCount } from '../../utils';
import RoleFilter from './RoleFilter';
import { fetchExportRecordsCount, fetchTemplates } from '../../apiclient';
import _ from 'lodash';

const SecondPage = forwardRef((props, fieldRef) => {
    const exports = useSelector((state) => state.export)
    const dispatch = useDispatch()
    const [roleOpen, setIsRoleOpen] = useState(false);
    const [fieldsErrorMsg, setFieldsErrorMsg] = useState('');
    const [exportType, setExportType] = useState(exports.exportCriteria.setting.type);
    const [exportTemplateMsg, setExportTemplateMsg] = useState('')
    const [isProjectsChecked, setIsProjectsChecked] = useState(false);
    const [isCompaniesChecked, setIsCompaniesChecked] = useState(false);
    const [isContactsChecked, setIsContactsChecked] = useState(false);
    const [rolesText, setRolesText] = useState('')
    const [isExcludeContactsWithNoConfirmedName, setIsExcludeContactsWithNoConfirmedName] = useState(exports.exportCriteria?.setting?.excludeContactWithNoConfirmedName)
    const [isExcludeContactsWithNoEmail, setIsExcludeContactsWithNoEmail] = useState(exports.exportCriteria?.setting?.excludeContactWithNoEmail)
    const [excludeMailedProjects, setExcludeMailedProjects] = useState(exports.exportCriteria?.setting?.excludeMailedProjects)
    const [excludeMailedCompanies, setExcludeMailedCompanies] = useState(exports.exportCriteria?.setting?.excludeMailedCompanies)
    const [avoidDuplicates, setAvoidDuplicates] = useState(exports.exportCriteria?.setting?.avoidDuplicates)
    const [excludeMailedContacts, setExcludeMailedContacts] = useState(exports.exportCriteria?.setting?.excludeMailedContacts)
    const [excludeMailedProjectsInterval, setExcludeMailedProjectsInterval] = useState(exports.exportCriteria?.setting?.excludeMailedProjectsInterval)
    const [excludeMailedCompaniesInterval, setExcludeMailedCompaniesInterval] = useState(exports.exportCriteria?.setting?.excludeMailedCompaniesInterval)
    const [excludeMailedContactsInterval, setExcludeMailedContactsInterval] = useState(exports.exportCriteria?.setting?.excludeMailedContactsInterval)
    const settings = useSelector((state) => state.settings)

    const selectProjectsFieldStepRef = useRef(null);
    const selectCompaniesFieldStepRef = useRef(null);
    const selectContactsFieldStepRef = useRef(null);
    const rolesDropdownRef = useRef(null);

    useImperativeHandle(fieldRef, () => ({
        isValidated() {
            const validateNewInput = _validateData() // run the new input against the validator
            let isDataValid = false

            // if full validation passes then save to store and pass as valid
            if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
                isDataValid = true;
            }
            else {
                _validationErrors(validateNewInput);
            }
            return isDataValid
        }
    }));
    useEffect(() => {
        setIsCompaniesChecked(exports.isCompaniesChecked)
        setIsContactsChecked(exports.isContactsChecked)
        setIsProjectsChecked(exports.isProjectsChecked)

    }, [exports.isCompaniesChecked, exports.isContactsChecked, exports.isProjectsChecked])


    const _validateData = () => {
        if (exportType === "Projects") {
            return {
                isProjectFieldsChecked: isProjectsChecked,
                exportTemplateVal: ((selectProjectsFieldStepRef.current?.templateName === false
                    || selectProjectsFieldStepRef.current?.templateName === null
                    || selectProjectsFieldStepRef.current?.templateName === '')
                    && selectProjectsFieldStepRef.current?.isCreateTemplate) ? false : true
            }
        } else if (exportType === "Companies") {
            return {
                isCompaniesFieldsChecked: isCompaniesChecked,
                exportTemplateVal: ((selectCompaniesFieldStepRef.current?.templateName === false
                    || selectCompaniesFieldStepRef.current?.templateName === null
                    || selectCompaniesFieldStepRef.current?.templateName === '')
                    && selectCompaniesFieldStepRef.current?.isCreateTemplate) ? false : true
            }
        } else {
            return {
                isContactsFieldsChecked: isContactsChecked,
                exportTemplateVal: ((selectContactsFieldStepRef.current?.templateName === false
                    || selectContactsFieldStepRef.current?.templateName === null
                    || selectContactsFieldStepRef.current?.templateName === '')
                    && selectContactsFieldStepRef.current?.isCreateTemplate) ? false : true
            }
        }
    }

    const _validationErrors = (val) => {
        if (exportType === "Projects") {
            setFieldsErrorMsg(val.isProjectFieldsChecked ? '' : 'Required')
        } else if (exportType === "Companies") {
            setFieldsErrorMsg(val.isCompaniesFieldsChecked ? '' : 'Required')
        } else {
            setFieldsErrorMsg(val.isContactsFieldsChecked ? '' : 'Required')
        }
        setExportTemplateMsg(val.exportTemplateVal ? '' : 'Template name is required')
    }

    const handleValidationCheck = (isFieldSelected) => {
        if (isFieldSelected) {
            setFieldsErrorMsg('');
        }
        if (exportType === 'Projects') {
            setIsProjectsChecked(isFieldSelected);
            setIsCompaniesChecked(false);
        } else if (exportType === 'Companies') {
            setIsCompaniesChecked(isFieldSelected);
            setIsProjectsChecked(false);
        } else {
            setIsContactsChecked(isFieldSelected);
            setIsCompaniesChecked(false);
        }
    };

    const ref = useRef();

    useEffect(() => {
        setExportType(exports.exportCriteria.setting.type);
        setIsExcludeContactsWithNoConfirmedName(exports.exportCriteria.setting.excludeContactWithNoConfirmedName);
        setIsExcludeContactsWithNoEmail(exports.exportCriteria.setting.excludeContactWithNoEmail);
        // setIsExcludeMailed(exports.exportCriteria.setting.excludeMailed);
        setExcludeMailedProjects(exports.exportCriteria.setting.excludeMailedProjects);
        setExcludeMailedCompanies(exports.exportCriteria.setting.excludeMailedCompanies);
        setExcludeMailedContacts(exports.exportCriteria.setting.excludeMailedContacts);
        setExcludeMailedProjectsInterval(exports.exportCriteria.setting.excludeMailedProjectsInterval);
        setExcludeMailedCompaniesInterval(exports.exportCriteria.setting.excludeMailedCompaniesInterval);
        setExcludeMailedContactsInterval(exports.exportCriteria.setting.excludeMailedContactsInterval);
        setRolesText(appendFieldDisplayNames(exports.exportFilters?.includeRoles?.options, false));
        setAvoidDuplicates(exports.exportCriteria.setting.avoidDuplicates);

        if (exports.isProjectsChecked || exports.isCompaniesChecked || exports.isContactsChecked) {
            handleValidationCheck(true);
        }
    }, [exports]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (rolesDropdownRef.current && !rolesDropdownRef.current.contains(event.target)) {
                setIsRoleOpen(false);
            }
        };
        // Adding the event listener to the whole document
        document.addEventListener('click', handleOutsideClick);
        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    function handleTemplateNameCheck(value) {
        if (value) {
            setExportTemplateMsg('')
        }
    }

    const handleSetExportTemplateTag = () => {
        setExportTemplateMsg(null)
    }

    function handleExcludeContactsWithNoConfirmedNameClick(event) {
        event.stopPropagation()
        dispatch(updateExcludeContactsWithNoConfirmedName(event.target.checked))
    }

    function handleExcludeContactsWithNoEmailClick(event) {
        event.stopPropagation()
        dispatch(updateExcludeContactsWithNoEmail(event.target.checked))
    }

    function getDateBasedOnInterval(interval) {
        let data = interval.split('-')
        let returnDate = moment().format("YYYY-MM-DD 00:00:00")
        if (data.length == 2) {
            if (data[1] === "D") {
                returnDate = moment().subtract(data[0], 'days').format("YYYY-MM-DD 00:00:00")
            } else if (data[1] === "M") {
                returnDate = moment().subtract(data[0], 'months').format("YYYY-MM-DD 00:00:00")
            }
        }
        return returnDate
    }

    function handleExcludeMailed(event, type) {
        event.stopPropagation()

        let date = getDateBasedOnInterval("7-D")

        let objectToUpdate = {}
        if (type === "projects") {
            objectToUpdate = {
                excludeMailedProjects: event.target.checked,
                excludeMailedProjectsInterval: date
            }
        }
        if (type === "companies") {
            objectToUpdate = {
                excludeMailedCompanies: event.target.checked,
                excludeMailedCompaniesInterval: date
            }
        }
        if (type === "contacts") {
            objectToUpdate = {
                excludeMailedContacts: event.target.checked,
                excludeMailedContactsInterval: date
            }
        }
        if (type === "duplicates") {
            objectToUpdate = {
                avoidDuplicates: event.target.checked
            }
        }
        dispatch(updateExcludeMailed(objectToUpdate))
    }

    function handleIntervalChange(event, type) {
        event.stopPropagation()
        let objectToUpdate = {}

        let date = getDateBasedOnInterval(event.target.value)

        if (type === "projects") {
            let checked = exports.exportCriteria?.setting?.excludeMailedProjects &&
                exports.exportCriteria?.setting?.excludeMailedProjects === true ? true : false
            objectToUpdate = {
                excludeMailedProjects: checked,
                excludeMailedProjectsInterval: date
            }
        }
        if (type === "companies") {
            let checked = exports.exportCriteria?.setting?.excludeMailedCompanies &&
                exports.exportCriteria?.setting?.excludeMailedCompanies === true ? true : false
            objectToUpdate = {
                excludeMailedCompanies: checked,
                excludeMailedCompaniesInterval: date
            }
        }
        if (type === "contacts") {
            let checked = exports.exportCriteria?.setting?.excludeMailedContacts &&
                exports.exportCriteria?.setting?.excludeMailedContacts === true ? true : false
            objectToUpdate = {
                excludeMailedContacts: checked,
                excludeMailedContactsInterval: date
            }
        }
        dispatch(updateExcludeMailed(objectToUpdate))
    }

    const handleRefreshCount = () => {
        if (exports.exportCountQuery) {
            let value = _.clone(exports.exportCountQuery)
            value = extractPayloadForRolesAndMailedExportCount(exports.exportCriteria, value);
            props.updateRefreshCountQuery(value)
            fetchExportRecordsCount(value, dispatch)
        }
    }

    const handleRolesDropDownClick = () => {
        setIsRoleOpen(!roleOpen)
    }

    // useEffect(()=>{
    //     fetchTemplates(dispatch)
    // }, [])

    useEffect(() => {
        handleRefreshCount()
    }, [exports?.exportFilters?.includeRoles])

    return (
        <>
            <div className="export-page ps-0">
                {/* <ColoredScrollbars className="export-panel-scrollbar" filterState={filterSmoothScroll}> */}
                <div>
                    {
                        exportType === "Projects" ? (
                            <SelectProjectsFieldStep ref={selectProjectsFieldStepRef}
                                fieldsErrorMsg={fieldsErrorMsg} exportTemplateMsg={exportTemplateMsg} handleSetExportTemplateTag={handleSetExportTemplateTag}
                                handleValidationCheck={handleValidationCheck} handleTemplateNameCheck={handleTemplateNameCheck}
                            />
                        ) :
                            exportType === "Companies" ? (
                                <SelectCompaniesFieldStep fieldsErrorMsg={fieldsErrorMsg} ref={selectCompaniesFieldStepRef}
                                    handleSetExportTemplateTag={handleSetExportTemplateTag}
                                    exportTemplateMsg={exportTemplateMsg} handleValidationCheck={handleValidationCheck}
                                    handleTemplateNameCheck={handleTemplateNameCheck} />
                            ) : (
                                <SelectContactsFieldStep fieldsErrorMsg={fieldsErrorMsg} exportTemplateMsg={exportTemplateMsg}
                                    handleValidationCheck={handleValidationCheck} handleTemplateNameCheck={handleTemplateNameCheck}
                                    ref={selectContactsFieldStepRef}
                                />
                            )
                    }
                    <div className="input-container">
                        <span className='title'>Include Roles</span>
                        <div className="role-container check-box-button">
                            <div className="field-panel" ref={rolesDropdownRef}>
                                <div id="roles" onClick={handleRolesDropDownClick} className="auto-complete" >
                                    {rolesText ? rolesText : 'Select one or more roles to include in the export'}
                                </div>
                                {
                                    roleOpen ? (
                                        <RoleFilter filter={exports?.exportFilters?.includeRoles}
                                            isProjectSelected={false}
                                            favouriteRoles={settings?.preferences?.favourite_roles} />
                                    ) : ('')
                                }
                            </div>
                        </div>
                    </div>
                    <div className="input-container d-flex align-items-center">
                        <div className="pr-3 refresh-count-block">
                            {
                                exports?.isContactsChecked || (exports?.exportCriteria.setting?.selectedTemplate?.toLowerCase().includes("letter")) || (exports?.exportCriteria.setting?.selectedTemplate?.toLowerCase().includes("email")) || (exports?.exportCriteria.setting?.selectedTemplate?.toLowerCase().includes("contact")) ? (
                                    <div>
                                        <div className={`check-box-button mb-3`}>
                                            <Input type="checkbox" checked={isExcludeContactsWithNoConfirmedName} id='excludeContactWithNoConfirmedName' onChange={handleExcludeContactsWithNoConfirmedNameClick} />
                                            <Label for="excludeContactWithNoConfirmedName">Exclude companies with no confirmed contact name</Label>
                                        </div>
                                        <div className={`check-box-button my-3`}>
                                            <Input type="checkbox" checked={isExcludeContactsWithNoEmail} id='excludeContactWithNoEmail' onChange={handleExcludeContactsWithNoEmailClick} />
                                            <Label for="excludeContactWithNoEmail">Exclude contacts with no personal email address</Label>
                                        </div>
                                    </div>
                                ) :
                                    (
                                        <div>
                                            <div className={`check-box-button disabled mb-3`}>
                                                <Input disabled type="checkbox" checked={isExcludeContactsWithNoConfirmedName} id='excludeContactWithNoConfirmedName' onChange={handleExcludeContactsWithNoConfirmedNameClick} />
                                                <Label for="excludeContactWithNoConfirmedName">Exclude companies with no confirmed contact name</Label>
                                            </div>
                                            <div className={`check-box-button disabled my-3`}>
                                                <Input disabled type="checkbox" checked={isExcludeContactsWithNoEmail} id='excludeContactWithNoEmail' onChange={handleExcludeContactsWithNoEmailClick} />
                                                <Label for="excludeContactWithNoEmail">Exclude contacts with no personal email address</Label>
                                            </div>
                                        </div>
                                    )
                            }
                            <div>
                                {exports?.exportCriteria.setting.type == "Projects" && ((exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("letter")) || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("email")) || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("contact"))) ?
                                    <div className={`check-box-button mt-3`}>
                                        <Input type="checkbox" id='avoidDuplicates' checked={avoidDuplicates}
                                            onChange={(e) => handleExcludeMailed(e, "duplicates")} />
                                        <Label for="avoidDuplicates">Avoid duplicates</Label>
                                    </div> : ""
                                }
                                {exports?.exportCriteria.setting.type == "Projects" && !exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("letter") && !exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("email") && !exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("contact") ?
                                    <div className={`check-box-button mt-3`}>
                                        <Input type="checkbox" id='avoidDuplicates' checked={avoidDuplicates}
                                            onChange={(e) => handleExcludeMailed(e, "duplicates")} />
                                        <Label for="avoidDuplicates">Avoid duplicates</Label>
                                    </div> : ""
                                }
                                {exports?.isProjectsChecked || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("letter")) || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("email")) || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("contact")) ? (
                                    <div className={`check-box-button my-3`}>
                                        <Input type="checkbox" id='excludeMailedProjects' checked={excludeMailedProjects}
                                            onChange={(e) => handleExcludeMailed(e, "projects")} />
                                        <Label for="excludeMailedProjects">Exclude projects mailed in</Label>
                                        <IntervalSelector selectedValue={excludeMailedProjectsInterval} onChange={(e) => handleIntervalChange(e, "projects")}></IntervalSelector>
                                    </div>
                                ) : (
                                    <div className={`check-box-button disabled my-3`}>
                                        <Input disabled type="checkbox" id='excludeMailedProjects' checked={excludeMailedProjects}
                                            onChange={(e) => handleExcludeMailed(e, "projects")} />
                                        <Label for="excludeMailedProjects">Exclude projects mailed in</Label>
                                        <IntervalSelector selectedValue={excludeMailedProjectsInterval} onChange={(e) => handleIntervalChange(e, "projects")}></IntervalSelector>
                                    </div>
                                )
                                }
                                {exports?.isCompaniesChecked || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("letter")) || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("email")) || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("contact")) ? (
                                    <div className={`check-box-button mt-3`}>
                                        <Input type="checkbox" id='excludeMailedCompanies' checked={excludeMailedCompanies}
                                            onChange={(e) => handleExcludeMailed(e, "companies")} />
                                        <Label for="excludeMailedCompanies">Exclude companies mailed in</Label>
                                        <IntervalSelector selectedValue={excludeMailedCompaniesInterval} onChange={(e) => handleIntervalChange(e, "companies")}></IntervalSelector>
                                    </div>
                                ) : (
                                    <div className={`check-box-button disabled mt-3`}>
                                        <Input type="checkbox" id='excludeMailedCompanies' checked={excludeMailedCompanies}
                                            onChange={(e) => handleExcludeMailed(e, "companies")} />
                                        <Label for="excludeMailedCompanies">Exclude companies mailed in</Label>
                                        <IntervalSelector selectedValue={excludeMailedCompaniesInterval} onChange={(e) => handleIntervalChange(e, "companies")}></IntervalSelector>
                                    </div>
                                )
                                }
                                {exports?.isContactsChecked || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("letter")) || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("email")) || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("contact")) ? (
                                    <div className={`check-box-button mt-3`}>
                                        <Input type="checkbox" id='excludeMailedContacts' checked={excludeMailedContacts}
                                            onChange={(e) => handleExcludeMailed(e, "contacts")} />
                                        <Label for="excludeMailedContacts">Exclude contacts mailed in</Label>
                                        <IntervalSelector selectedValue={excludeMailedContactsInterval} onChange={(e) => handleIntervalChange(e, "contacts")}></IntervalSelector>
                                    </div>
                                ) : (
                                    <div className={`check-box-button disabled mt-3`}>
                                        <Input type="checkbox" id='excludeMailedContacts' checked={excludeMailedContacts}
                                            onChange={(e) => handleExcludeMailed(e, "contacts")} />
                                        <Label for="excludeMailedContacts">Exclude contacts mailed in</Label>
                                        <IntervalSelector selectedValue={excludeMailedContactsInterval} onChange={(e) => handleIntervalChange(e, "contacts")}></IntervalSelector>
                                    </div>
                                )
                                }
                                {exports?.exportCriteria.setting.type != "Projects" && ((exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("letter")) || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("email")) || (exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("contact"))) ?
                                    <div className={`check-box-button mt-3`}>
                                        <Input type="checkbox" id='avoidDuplicates' checked={avoidDuplicates}
                                            onChange={(e) => handleExcludeMailed(e, "duplicates")} />
                                        <Label for="avoidDuplicates">Avoid duplicates</Label>
                                    </div> : ""
                                }
                                {exports?.exportCriteria.setting.type != "Projects" && !exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("letter") && !exports?.exportCriteria?.setting?.selectedTemplate?.toLowerCase().includes("email") && !exports?.exportCriteria.setting.selectedTemplate.toLowerCase().includes("contact") ?
                                    <div className={`check-box-button mt-3`}>
                                        <Input type="checkbox" id='avoidDuplicates' checked={avoidDuplicates}
                                            onChange={(e) => handleExcludeMailed(e, "duplicates")} />
                                        <Label for="avoidDuplicates">Avoid duplicates</Label>
                                    </div> : ""
                                }
                            </div>
                        </div>
                        <div className="pl-3">
                            <button className="btn btn-outline-cerulean" onClick={handleRefreshCount}>Refresh Count</button>
                        </div>
                    </div>
                </div>
                {/* </ColoredScrollbars> */}
            </div>
        </>
    )
})

export default SecondPage
