import React, { useEffect, useRef, useState } from 'react';
import { FormGroup, Row, Col, Input, Label } from 'reactstrap';
import Slider, { Range } from 'rc-slider';
import Icon from '../../common/js/icon';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { updateValue } from './redux/reducer';

const ValueFilter = (props) => {
    const filter = useSelector((state) => state.filter)
    const [state, setState] = useState({
        valueDataFrom: [],
        selectedDataFrom: {
            value: '',
            label: ''
        },
        valueDataTo: [],
        selectedDataTo: {
            value: '',
            label: ''
        },
        textUpdate: false
    });
    const [validationError, setValidationError] = useState(false)
    const [selectedDataFrom, setSelectedDataFrom] = useState({value: filter.filtersState[filter.selectedSearchType].value.displayFrom,label: filter.filtersState[filter.selectedSearchType].value.displayFrom})
    const [selectedDataTo, setSelectedDataTo] = useState({value: filter.filtersState[filter.selectedSearchType].value.displayTo,label: filter.filtersState[filter.selectedSearchType].value.displayTo})
    const dispatch = useDispatch()
    const startValue = useRef();
    const endValue = useRef();

    const evaluateValue = (inputParam) => {
        var returnList = new Array();
        if (inputParam < 100) {
            var inputParamString = inputParam + "";
            if (inputParamString.length == 1) {
                returnList.push({ value: "£" + inputParamString + "00" + "k", label: "£" + inputParamString + "00" + "k" })
                returnList.push({ value: "£" + inputParamString + "m", label: "£" + inputParamString + "m" })
                returnList.push({ value: "£" + inputParamString + "bn", label: "£" + inputParamString + "bn" })
            }
            if (inputParamString.length == 2) {
                returnList.push({ value: "£" + inputParamString + "0" + "k", label: "£" + inputParamString + "0" + "k" })
                returnList.push({ value: "£" + inputParamString + "m", label: "£" + inputParamString + "m" })
                returnList.push({ value: "£" + inputParamString + "bn", label: "£" + inputParamString + "bn" })
            }
        }
        else {
            returnList.push({ value: "£" + inputParam + "k", label: "£" + inputParam + "k" })
            returnList.push({ value: "£" + inputParam + "m", label: "£" + inputParam + "m" })
            returnList.push({ value: "£" + inputParam + "bn", label: "£" + inputParam + "bn" })
        }
        return returnList;
    }


    const handleFromChange = (textInput) => {
        if (!isNaN(textInput)) {
            setState({ valueDataFrom: evaluateValue(textInput) })
        }
    }

    const handleFromChangeAfter = (ChangedValue) => {
        setSelectedDataFrom(ChangedValue)
        validateInput(ChangedValue, selectedDataTo)
    }

    const handleToChange = (textInput) => {
        if (!isNaN(textInput)) {
            setState({ valueDataTo: evaluateValue(textInput) })
        }
    }

    const handleToChangeAfter = (ChangedValue) => {
        setSelectedDataTo(ChangedValue)
        validateInput(selectedDataFrom, ChangedValue)
    }

    const validateInput = (from, to) => {
        var fromInput = from ? from.value : ""
        var toInput = to ? to.value : ""
        var fromParam = ""
        var toParam = ""
        var fromInt = fromInput && parseInt(fromInput.replace("£", "").replace("k", "").replace("m", "").replace("bn", ""))
        var toInt = toInput && parseInt(toInput.replace("£", "").replace("k", "").replace("m", "").replace("bn", ""))
        var isValidationError
        if ((from != null || to != null) &&
            ((from && from.value !== "") || (to && to.value !== ""))) {
            if (fromInput) {
                if (fromInput.indexOf("k") !== -1) {
                    fromParam = "k"
                }
                else if (fromInput.indexOf("m") !== -1) {
                    fromParam = "m"
                }
                else if (fromInput.indexOf("bn") !== -1) {
                    fromParam = "bn"
                }
            }
            if (toInput) {
                if (toInput.indexOf("k") !== -1) {
                    toParam = "k"
                }
                else if (toInput.indexOf("m") !== -1) {
                    toParam = "m"
                }
                else if (toInput.indexOf("bn") !== -1) {
                    toParam = "bn"
                }
            }
            if ((fromParam == "bn" && toParam == "m") || (fromParam == "bn" && toParam == "k") || (fromParam == "m" && toParam == "k")) {
                setValidationError(true)
                isValidationError = true
            }
            else if ((fromParam == toParam) && fromInt > toInt) {
                setValidationError(true)
                isValidationError = true
            }
            else {
                setValidationError(false)
                isValidationError = false
            }
        }
        else {
            setValidationError(false)
            isValidationError = false
        }
        let payload = {
            from: fromParam == "k" ?
                fromInt * 1000 :
                fromParam == "m" ? fromInt * 1000000 :
                    fromInt * 1000000000,
            to: toInt ? toParam == "k" ?
                toInt * 1000 :
                toParam == "m" ? toInt * 1000000 :
                    toInt * 1000000000 : null,
            displayFrom: from ? from.value : "",
            displayTo: to ? to.value : "",
            active: fromParam || toParam ? true : false
        }
        dispatch(updateValue(payload))
        if (!isValidationError) {
            props.fetchEntityCounts({ initTags: props.initTags, badgeCount: props.handleBadgeCount })
        }
    }

    const handleRemove = (type) => {
        if(type === 'startValue'){
            setSelectedDataFrom({value: '',label: ''})
            validateInput({value: '',label: ''}, selectedDataTo)
        }else{
            setSelectedDataTo({value: '',label: ''})
            validateInput(selectedDataFrom ,{value: '',label: ''})
        }
       
    }

    useEffect(() => {
        let valueFilter = filter?.filtersState[filter.selectedSearchType].value
        setSelectedDataFrom({value: valueFilter.displayFrom,label: valueFilter.displayFrom})
        setSelectedDataTo({value: valueFilter.displayTo ,label: valueFilter.displayTo})
      }, [filter?.filtersState[filter.selectedSearchType].value]);

    return (
        <div className="advanced-filter-grid" id="new_filter_value">
            <div className="filterHeading">
                <h5 className="mb-3">Value</h5>
            </div>
            <div className={`filterBlock ${(selectedDataFrom && selectedDataFrom.value) || (selectedDataTo && selectedDataTo.value) ? "activeBlock" : ""}`}>
                <div className="textcontent">
                    <Row className="align-items-center">
                        <Col xs={12} sm={8} md={8} lg={11}>Filter by project value, enter one or more values to set the range.</Col>
                    </Row>
                </div>
                <div className={`value-filter-wrapper ${validationError ? 'error-field-validation' : ""}`}>
                    <FormGroup id="updatedFilter" name="updatedFilter" row className="">
                        <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                            <div className="value-frames update-location-slider-frames">
                                <Row className="mb-3">
                                    <Col xs={12} sm={8} md={8} lg={11}>Show projects with values between</Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <Row className="position-relative">
                                            <div className="col-sm-3">
                                                <Label className="col-form-label">From</Label>
                                            </div>
                                            <div className="col-sm-9">
                                                <Select value={selectedDataFrom} options={state.valueDataFrom} onInputChange={handleFromChange} onChange={handleFromChangeAfter} disabled={state.textUpdate} />
                                                <Icon color="#999" size={10} icon="icon-gen-cross" className="me-1 value-cross" title="Clear value" onClick={() => handleRemove('startValue')} />
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <Row className="position-relative">
                                            <div className="col-sm-2">
                                                <Label className="col-form-label">To</Label>
                                            </div>
                                            <div className="col-sm-10">
                                                <Select placeholder="No limit" value={selectedDataTo} options={state.valueDataTo} onInputChange={handleToChange} onChange={handleToChangeAfter} disabled={state.textUpdate} />
                                                <Icon color="#999" size={10} icon="icon-gen-cross" className="me-1 value-cross" title="Clear value" onClick={() => handleRemove('endValue')} />
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                                
                            </div>
                        </Col>
                    </FormGroup>
                </div>
                {validationError ?
                    <p className="text-danger text-left">From field must be smaller than or equal to to field</p> :""
                }
            </div>
        </div>
    )

}
export default ValueFilter;