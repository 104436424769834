import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap'
import _ from 'lodash';

import { fetchLocation } from "../apiclient"
import { MAPBOX_TOKEN, MIN_KM, MIN_MILES } from "../../../utils/settings"
import { updateQueryResult } from "../redux/reducer"
import { updateLocationSearchObj } from '../redux/searchPeristReducer';


const AutoComplete = (props) => {
    const [listShow, setListShow] = useState(false)
    const [cursor, setCursor] = useState(-1)
    const dispatch = useDispatch();
    const search = useSelector((state) => state.search)
    const searchPersist = useSelector((state) => state.searchPersist)
    const filter = useSelector((state) => state.filter)
    const [inputValObj, setInputObj] = useState(props.searchLocationObject)
    const [isClearedSearched, setClearedSearch] = useState(false)
    const [context] = useState(searchPersist.appliedContext)
    const [filterContext] = useState(props.context)

    const handleChange = (value) => {
        setInputObj(current =>
        ({
            ...current,
            [context]: {
                ...current[context],
                location: value
            }
        }));
        if (value.length > 2 && value.length < 30) {
            fetchLocation(props.country, value, props.limit, MAPBOX_TOKEN, dispatch)
            setListShow(true)
            setCursor(-1)
        }
    }

    useEffect(() => {
        if (props.clearSearchQuery) {
            setInputObj(current =>
            ({
                ...current,
                [context]: {
                    ...current[context],
                    location: '', latitude: "", longitude: "", distance: ""
                }
            }));
            setCursor(-1)
            setClearedSearch(true)
        }
    }, [props.clearSearchQuery]);

    useEffect(() => {
        if (props.isRadialSearchActive && isClearedSearched) {
            dispatch(updateLocationSearchObj(_.cloneDeep(inputValObj)));
            setCursor(-1)
        }

    }, [props.isRadialSearchActive]);
    useEffect(() => {
        if (props.isSetCurrentLocation) {
            setInputObj(current =>
            ({
                ...current,
                [context]: {
                    ...current[context],
                    location: 'Current Location'
                }
            }));
            setCursor(-1)
            setClearedSearch(true)
        }
    }, [props.isSetCurrentLocation]);

    useEffect(() => {
        if (listShow) {
            document.addEventListener('click', handleOutsideClick, false)
        }
    }, [search.searchLocationQueryResults])

    useEffect(() => {
        if (filter.filtersState[searchPersist.appliedContext]?.location.active && filterContext === 'project') {
            setInputObj((prevState) => ({
                ...prevState,
                [searchPersist.appliedContext]: {
                    ...prevState[searchPersist.appliedContext],
                    location: filter.filtersState[searchPersist.appliedContext]?.location.location
                }

            }))
        }
    }, [filter.filtersState]);

    const handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        if (e.srcElement.placeholder !== "Location" && listShow) {
            setListShow(false)
            dispatch(updateQueryResult([]))
        }
    }

    const handleKeyDown = (event) => {
        event.stopPropagation()
        if (event.keyCode === 38 && cursor > 0) {
            if (search && search.searchLocationQueryResults && search.searchLocationQueryResults.features && search.searchLocationQueryResults.features.length > 0) {
                search.searchLocationQueryResults.features.map(function (place, index) {
                    if (index == cursor - 1) {
                        props.onSuggestionSelect(
                            place.place_name,
                            place.center[1],
                            place.center[0],
                            place.text,
                            MIN_KM
                        )
                        inputValObj[context].location = place.place_name;
                        setInputObj(_.cloneDeep(inputValObj))
                    }
                })
            }
            setCursor((prevState) => prevState.cursor - 1)
        } else if (event.keyCode === 40 && search && search.searchLocationQueryResults && search.searchLocationQueryResults.features && cursor < search.searchLocationQueryResults.features.length - 1) {
            if (search.searchLocationQueryResults && search.searchLocationQueryResults.features && search.searchLocationQueryResults.features.length > 0) {
                search.searchLocationQueryResults.features.map(function (place, index) {
                    if (index == cursor + 1) {
                        props.onSuggestionSelect(
                            place.place_name,
                            place.center[1],
                            place.center[0],
                            place.text,
                            MIN_KM
                        )
                    }
                    inputValObj[context].location = place.place_name;
                    setInputObj(_.cloneDeep(inputValObj))
                })
            }
            setCursor((prevState) => prevState.cursor + 1)
        }
        else if (event.keyCode === 9 || event.keyCode === 13) {
            event.preventDefault()
            dispatch(updateQueryResult([]))
        }

    }
    const onSuggestionSelect = (event) => {
        props.onSuggestionSelect(
            event.target.dataset.suggestion,
            event.target.dataset.lat,
            event.target.dataset.lng,
            event.target.dataset.text,
            MIN_MILES
        )
        dispatch(updateQueryResult([]))
        inputValObj[context].location = event.target.dataset.suggestion;
        setInputObj(_.cloneDeep(inputValObj))
        setListShow(false)
    }
    return (
        <div>
            <Input
                type='text'
                value={inputValObj && inputValObj[context] && inputValObj[context].location !== undefined ? inputValObj[context].location : ""}
                placeholder={props.placeholder || "Search"}
                onChange={(e) => { handleChange(e.target.value) }}
                onKeyDown={handleKeyDown}
            />
            <span>
                <div className={listShow ? 'display-block react-mapbox-ac-menu' : 'display-none'} >
                    {search && search.searchLocationQueryResults && search.searchLocationQueryResults.features && search.searchLocationQueryResults.features.length > 0 ? (
                        search.searchLocationQueryResults.features.map(function (place, index) {
                            return (
                                <div className='react-mapbox-ac-suggestion'
                                    style={cursor === index ? { backgroundColor: '#e0dee0' } : { color: '#292b2c' }}
                                    onClick={(e) => onSuggestionSelect(e)}
                                    key={index}
                                    data-suggestion={place.place_name}
                                    data-lng={place.center[0]}
                                    data-lat={place.center[1]}
                                    data-text={place.text}>
                                    {place.place_name}
                                </div>
                            )
                        })
                    ) : ('')
                    }
                </div>
            </span>
        </div>
    )
}

export default AutoComplete
