import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import CommonPlaceholder from '../../details/framework/commonPlaceholder';
import ProjectRow from './projectRow';
import { groupNotesbyUpdatedDate } from '../../../utils';

const ProjectList = (props) => {
    const search = useSelector((state) => state.search)
    const project = useSelector((state) => state.project)
    const myleads = useSelector((state) => state.myleads)
    const searchPersist = useSelector((state) => state.searchPersist)
    const [myLeadsListUpdatedDate, setMyLeadsListUpdatedDate] = useState([])

    useEffect(() => {
        if (props?.prevNextList === "myLeadsUpdates" && !myleads?.isListView) {
            setMyLeadsListUpdatedDate(groupNotesbyUpdatedDate(myleads?.updatesSearchList?.length > 0 ? myleads?.updatesSearchList : [], props?.prevNextList))
        } else if (props?.prevNextList === 'myLeadsHistory' && searchPersist?.leadsSort?.history?.[searchPersist?.leadsAppliedContext?.history]?.label === "last viewed" && !myleads?.isListView) {
            setMyLeadsListUpdatedDate(groupNotesbyUpdatedDate(myleads?.searchList?.length > 0 ? myleads?.searchList : [], props?.prevNextList))
        } else {
            setMyLeadsListUpdatedDate([])
        }
    }, [myleads?.searchList, myleads?.isListView, myleads?.updatesSearchList])

    return (<>
        {
            props?.prevNextList === 'searchList' || props?.prevNextList === 'selectedSearchList' ?
                (props.entityFlag ? search && search.listOfSelectedObjectIds && search.listOfSelectedObjectIds.length !== 0 ? (
                    search && search.listOfSelectedObjectIds.map((project, index) => (
                        <li className="list-group-item" id={project.project_id}>
                            <ProjectRow project={project} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} prevNextList={props.prevNextList} />
                        </li>
                    ))
                ) : ('') :
                    search?.clusterList?.leadsFlags?.length !== 0 ? (
                        search.clusterList.leadsFlags.map((project, index) => (
                            <li className="list-group-item" id={project.project_id}>
                                <ProjectRow mode={props.mode} prevNextList={props.prevNextList} project={project} isShowRelatedEntitiesMode={false} index={index} myHoverList={props.myHoverList} isHierarchy={true} />
                            </li>
                        ))
                    ) :
                        search && search.searchList && search.searchList.length !== 0 ?
                            (
                                search && search.searchList && search.searchList.map((project, index) => (
                                    <li className="list-group-item" id={project.project_id}>
                                        <ProjectRow mode={props.mode} prevNextList={props.prevNextList} project={project} isShowRelatedEntitiesMode={false} index={index} myHoverList={props.myHoverList} />
                                    </li>
                                ))
                            ) :
                            ('')) :
                props?.prevNextList === 'groupedCompanyProjects' ?
                    (
                        search && search.groupCompanyProjects && search.groupCompanyProjects.length !== 0 ?
                            (
                                search && search.groupCompanyProjects && search.groupCompanyProjects.map((project, index) => (
                                    <li className="list-group-item" id={project.project_id}>
                                        <ProjectRow project={project} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} prevNextList={props.prevNextList} />
                                    </li>
                                ))
                            ) : ('')
                    ) :
                    search?.clusterList?.leadsFlags?.length !== 0 ? (
                        search.clusterList.leadsFlags.map((project, index) => (
                            <li className="list-group-item" id={project.project_id}>
                                {
                                    props.entityFlag ?
                                        <ProjectRow project={project} prevNextList={props.prevNextList} activeTab={props.activeTab} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} />
                                        :
                                        <ProjectRow mode={props.mode} prevNextList={props.prevNextList} activeTab={props.activeTab} project={project} isShowRelatedEntitiesMode={false} index={index} myHoverList={props.myHoverList} />
                                }
                            </li>
                        ))
                    ) :
                        myleads?.searchList?.length !== 0 && myLeadsListUpdatedDate.length === 0 && props?.prevNextList !== "myLeadsUpdates" ?
                            (
                                myleads?.searchList?.map((project, index) => (
                                    <li className="list-group-item" id={project.project_id}>
                                        {
                                            props.entityFlag ?
                                                <ProjectRow project={project} prevNextList={props.prevNextList} activeTab={props.activeTab} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} />
                                                :
                                                <ProjectRow mode={props.mode} prevNextList={props.prevNextList} activeTab={props.activeTab} project={project} isShowRelatedEntitiesMode={false} index={index} myHoverList={props.myHoverList} />
                                        }
                                    </li>
                                ))
                            ) : myleads?.updatesSearchList?.length !== 0 && props?.prevNextList === "myLeadsUpdates" && myLeadsListUpdatedDate.length === 0 ?
                                (
                                    myleads?.updatesSearchList?.map((project, index) => (
                                        <li className="list-group-item" id={project.project_id}>
                                            {
                                                props.entityFlag ?
                                                    <ProjectRow project={project} prevNextList={props.prevNextList} activeTab={props.activeTab} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} />
                                                    :
                                                    <ProjectRow mode={props.mode} prevNextList={props.prevNextList} activeTab={props.activeTab} project={project} isShowRelatedEntitiesMode={false} index={index} myHoverList={props.myHoverList} />
                                            }
                                        </li>
                                    ))
                                ) : myLeadsListUpdatedDate.length !== 0 && props?.prevNextList === 'myLeadsUpdates' && !myleads?.isListView || myLeadsListUpdatedDate.length !== 0 && props?.prevNextList === 'myLeadsHistory' && searchPersist?.leadsSort?.history?.[searchPersist?.leadsAppliedContext?.history]?.label === "last viewed" ? (
                                    <>
                                        {Object.entries(myLeadsListUpdatedDate).map(([key, array]) => (
                                            <div className="note-listing pb-2" key={key}>
                                                <span className="update-date">{key}</span>
                                                {array.map((project, index) => (
                                                    <li className="list-group-item" id={project.project_id}>
                                                        {
                                                            props.entityFlag ?
                                                                <ProjectRow project={project} prevNextList={props.prevNextList} activeTab={props.activeTab} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} />
                                                                :
                                                                <ProjectRow mode={props.mode} prevNextList={props.prevNextList} activeTab={props.activeTab} project={project} isShowRelatedEntitiesMode={false} index={index} myHoverList={props.myHoverList} />
                                                        }
                                                    </li>
                                                ))}
                                            </div>
                                        ))}
                                    </>
                                ) : ('')

        }
    </>
    )
}

export default ProjectList