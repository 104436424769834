import React, { useState } from 'react';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap'
import { ArrowLeft } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { ABI_IOS_APP, ABI_PLAY_APP, ABILIVE } from './../../utils/settings'
import { forgotPassword } from './apiclient';
import { component, setAlert } from '../../redux/features/user/userslice';


const ForgotPassword = () => {
	const [ userNameOrEmail, setUserNameOrEmail ] = useState('');
    const dispatch = useDispatch();
    let navigate = useNavigate();

	const handleSubmit = (event) => {
        event.preventDefault()
		if (userNameOrEmail !== '') {
			let user = {
				username: userNameOrEmail.trim()
			}
			forgotPassword(user, navigate, dispatch)
		} else {
			dispatch(setAlert({
                visible: true,
                message : "Reset failed #Enter your email or username",
                type : "danger",
                keyid: Math.random()
            }))
		}
	};

    const backToLogin = () => {
	    dispatch(component(''))
        navigate("/");
	}

    const handleKeyDown = (event) => {
		if(event.keyCode === 13)
		{
		  handleSubmit(event)
		}
	}

    const handleChange = (event) => {
		setUserNameOrEmail(event.target.value);
	}

	return (
		<>
			<Col className="loginForm col-12 col-md-6 px-5">
                <p className="py-3">Forgot password</p>
                    <Form onKeyDown={handleKeyDown}
                    >
                    <FormGroup className="form-group">
                        <div className="icon-addon addon-lg">
                        <Input type="Text" id="userNameOrEmail" autoCapitalize="off" placeholder="Enter email or username" onChange={handleChange} 
                        autoComplete="off" />
                        <Label for="userNameOrEmail" className="sr-only fa fa-user" title="Username"></Label>
                        </div>
                    </FormGroup>
                    <FormGroup className="form-group">
                        <Col lg="12" className="my-4 p-0">
                        <button className="btn-block primary-btn" id='reset_password' onClick={handleSubmit.bind(this)} >Reset password</button>
                        </Col>
                        <Col lg="12" className="p-0">
                            <button id='back_login' className="btn btn-link btn-block btn-forgotpassword" onClick={backToLogin}><ArrowLeft />Back to Sign in</button>
                        </Col>
                    </FormGroup>
                    <p className="info">You'll need an active subscription to use the Barbour ABI app. To learn why we're the UK's leading supplier of construction intelligence, <a href={ABILIVE} target="_blank">visit our site</a>. </p>

                    <div className="app-btn">
                        <a className='ios' target="_blank" href={ABI_IOS_APP}></a>
                        <a className='android' target="_blank" href={ABI_PLAY_APP}></a>
                    </div>
                </Form>
            </Col>
        </>
	);
}

export default ForgotPassword;