import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import mapboxgl from "mapbox-gl"
import { ZoomControl, Marker, Cluster, Popup } from "react-mapbox-gl"
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Input, Label } from 'reactstrap'
import { Tooltip as ReactTooltip } from 'react-tooltip';
import _ from 'lodash'

import { Map, MYLEADS_PREVNEXT_HIERARCHY_LIST, MYLEADS_PREVNEXT_LIST } from '../../utils/settings'
import { applyFitbounds, dataFilter, filterID, getColor, getRoles, isEmpty, modifyClusterCoordinates, modifyCoordinates, popupRedirectClick } from './mapView'
import { displayCurrency, getFormatedDate, getFormatedStartOrEndDate, isValidText } from '../../utils'
import { resetRelationalEntititySelectionCount, rolesForProject, updateBackButtonCluster, updateBackClusterList, updateBackObjectId, updateClusterClickCount, updateClusterList, updateCompanyDetail, updateGroupCompanyProjectsFav, updateHierarchyBack, updatelistOfSelectedObjectIds, updateNavigationBack, updateObjectData, updateObjectId, updateOffsetLimitValue, updatePeopleProjectsList, updateProjectsForCompanyId, updateRemoveFilterToggle, updateSelectedSelectorControlValue, updateShowHierarchy, updateShowSelectedCompanies, updateShowSelectedProjects } from '../search/redux/reducer'
import { updateMyLeadsShowHierarchy } from '../myleads/reducer'
import { fetchRolesForCompanyId, fetchRolesForProjects } from '../search/apiclient'
import { updateGroupCompaniesDetails, updateIsProjectSelected, updateListSelection } from '../details/redux/reducer'
import { updateAdditionalQuery, updateLocationSearchObj, updateSortSearch } from '../search/redux/searchPeristReducer'
import { TOURPROJECTMAPVIEW } from '../../utils/tour'
import { convertMilesToYards } from '../search/utils'
import Icon from '../common/js/icon'
import { updateAdvanceLocation, updateFilterText, updateOffsetLimit, updateSearchHere, updateSort } from '../search/filters/redux/reducer'
import { fetchSortLabel } from '../../utils/commonFunctions'
const { styles: { newBasic, basic, flyToOptions, defaultCenterPosition, satellite }, cluster: { radius, maxZoom, setPadding } } = require('../../utils/config.json')

var GeoJSON = require('geojson')

var mapContainer = {}
let geoJsonData = []
let companyData = []
let projectData = []
let clusterData = { features: [], type: 'FeatureCollection' }
let browser = navigator.userAgent.match(/Edge/i) || navigator.userAgent.match(/Trident.*rv[ :]*11\./i) ? "msie" : "other"
var searchHerePosition = defaultCenterPosition
let geolocate = new mapboxgl.GeolocateControl({
    positionOptions: {
        enableHighAccuracy: true
    },
    fitBoundsOptions: {
        maxZoom: 9.5
    },
    trackUserLocation: false
});

const MapPanel = (props) => {
    let ifMyleadsExist = MYLEADS_PREVNEXT_LIST.includes(props.prevNextList) ? true : false
    let ifMyleadsHierarchyExist = MYLEADS_PREVNEXT_HIERARCHY_LIST.includes(props.prevNextList) ? true : false
    let myleadsTab = ['myLeadsTags', 'projectHierarchicalMyLeadsTags'].includes(props.prevNextList) ? 'tags'
        : ['myLeadsFavourites', 'projectHierarchicalMyLeadsFavourites'].includes(props.prevNextList) ? 'favourite'
            : ['myLeadsHistory', 'projectHierarchicalMyLeadsHistory'].includes(props.prevNextList) ? 'history'
                : ['myLeadsShares', 'projectHierarchicalMyLeadsShares'].includes(props.prevNextList) ? 'shares' : ''
    const search = useSelector((state) => state.search)
    const myleads = useSelector((state) => state.myleads)
    const searchPersist = useSelector((state) => state.searchPersist)
    const settings = useSelector((state) => state.settings)
    const details = useSelector((state) => state.project)
    const filter = useSelector((state) => state.filter)
    const dispatch = useDispatch()
    let navigate = useNavigate()
    let location = useLocation()

    const activeFilterValue = (props.prevNextList === 'myLeadsTags' || props.prevNextList === 'myLeadsUpdates' || props.prevNextList === 'myLeadsFavourites' || props.prevNextList === 'myLeadsHistory' || props.prevNextList === 'myLeadsShares' || props.prevNextList === 'myLeadsArchive') ? searchPersist?.leadsAppliedContext[props?.activeTab] : searchPersist?.appliedContext
    const [mapStyleURI, setMapStyleURI] = useState(browser == "msie" ? newBasic : basic)
    const [sidebarVisible] = useState(false)
    const [searchHereFlag, setSearchHereFlag] = useState(false)
    const [currentLocation, setCurrentLocation] = useState(false)
    const [popup, setPopup] = useState({})
    const [entity, setEntity] = useState({})
    const [popupClose, setPopupClose] = useState(false)
    const [popupActive, setPopupActive] = useState({})
    const [hoverList, setHoverList] = useState(props.hoverItem?.hoverList)
    const [hoverInfoStatus, setHoverInfoStatus] = useState(props.hoverItem?.hoverInfoStatus)
    const [markerActiveFlag, setMarkerActiveFlag] = useState(props.hoverItem?.markerActiveFlag)
    const [searchListData, setSearchListData] = useState([])
    const [leadsData, setLeadsData] = useState([]);
    const [mapCurrentLocation, setMapCurrentLocation] = useState(false)
    const [dragStart, setDragStart] = useState(false)
    const [searchAtMyLocation, setSearchAtMyLocation] = useState(false)

    useEffect(() => {
        setHoverList(props.hoverItem?.hoverList)
        setHoverInfoStatus(props.hoverItem?.hoverInfoStatus)
        setMarkerActiveFlag(props.hoverItem?.markerActiveFlag)
    }, [props.hoverItem])

    useEffect(() => {
        setDragStart(true)
        setSearchListData(search.searchList)
        setLeadsData(!ifMyleadsExist ? [] : leadsData)
        let projectHierarchy = ifMyleadsExist ? myleads.showProjectHierarchy : search.showProjectHierarchy
        if (!projectHierarchy && !details.isProjectSelected && !search.showCompanyHierarchy) {
            setMap(search.searchList)
        }
    }, [search.searchList, search.backObjectId, search.noResult]);

    useEffect(() => {
        if (ifMyleadsExist) {
            setLeadsData(props?.prevNextList === "myLeadsUpdates" ? myleads.updatesSearchList : myleads.searchList)
            if (!myleads.showProjectHierarchy) {
                setMap(props?.prevNextList === "myLeadsUpdates" ? myleads.updatesSearchList : myleads.searchList)
            }
            if (myleads.searchList.length > 0 || myleads.updatesSearchList.length > 0) {
                setSearchListData([])
            }
        }
    }, [myleads.searchList, myleads.noResult, search.backObjectId, myleads.updatesSearchList]);

    useEffect(() => {
        if (search.groupCompanyProjects?.length > 0) {
            setMap(search.groupCompanyProjects)
        }
    }, [search.groupCompanyProjects])

    useEffect(() => {
        if (!search.backButtonCluster?.back) {
            let loadData = search.groupCompanyProjects?.length ? search.groupCompanyProjects
                : ifMyleadsExist ? props?.prevNextList === "myLeadsUpdates" ? myleads.updatesSearchList
                    : myleads.searchList : search.searchList;
            let projectHierarchy = ifMyleadsExist ? myleads.showProjectHierarchy : search.showProjectHierarchy
            projectHierarchy && search?.projectHierarchicalRoles?.length ? showRelationalData(search.projectHierarchicalRoles)
                : search.showCompanyHierarchy && search?.projectsForCompanyId ? showRelationalData(search.projectsForCompanyId)
                    : setMap(loadData)
        }
    }, [search.backButtonCluster]);

    useEffect(() => {
        if (search.backButtonCluster?.back) {
            setMap(search.clusterList.leadsFlags)
        }
    }, [search.clusterList]);

    useEffect(() => {
        let loadData = search.backButtonCluster?.back ? search.clusterList.leadsFlags
            : search.groupCompanyProjects?.length ? search.groupCompanyProjects
                : ifMyleadsExist ? props?.prevNextList === "myLeadsUpdates" ? myleads.updatesSearchList
                    : myleads.searchList : search.searchList;
        let projectHierarchy = ifMyleadsExist ? myleads.showProjectHierarchy : search.showProjectHierarchy
        let entityData = projectHierarchy ? search.projectRowData : search.showCompanyHierarchy ? search.companyDetails : {}
        setEntity(entityData)
        projectHierarchy && search?.projectHierarchicalRoles?.length && entityData ? showRelationalData(search.projectHierarchicalRoles)
            : search.showCompanyHierarchy && search?.projectsForCompanyId && entityData ? showRelationalData(search.projectsForCompanyId)
                : setMap(loadData)
    }, [search?.showProjectHierarchy, search?.showCompanyHierarchy, search?.projectHierarchicalRoles,
    search?.projectsForCompanyId, myleads.showProjectHierarchy, search.projectRowData, search.companyDetails])

    useEffect(() => {
        let loadData = search.backButtonCluster?.back ? search.clusterList.leadsFlags
            : search.groupCompanyProjects?.length ? search.groupCompanyProjects
                : ifMyleadsExist ? props?.prevNextList === "myLeadsUpdates" ? myleads.updatesSearchList
                    : myleads.searchList : search.searchList;
        search?.listOfSelectedObjectIds?.length ? setMap(search.listOfSelectedObjectIds)
            : setMap(loadData)
    }, [search?.listOfSelectedObjectIds])

    useEffect(() => {
        if ((filter.filtersState[searchPersist.appliedContext]?.location?.location.length
            && filter.filtersState[searchPersist.appliedContext]?.location?.location.toLowerCase() === 'Current location') ||
            (filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location.length
                && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location.toLowerCase() === 'Current location')) {
            goBack()
            setCurrentLocation(mapCurrentLocation ? false : true)
            setSearchHereFlag(true)
            setMap([])
        } else if ((filter.filtersState[searchPersist.appliedContext]?.location?.location.length && searchPersist.appliedContext === 'project'
            && filter.filtersState[searchPersist.appliedContext]?.location?.location.toLowerCase() === 'Dropped pin') ||
            (filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location.length && searchPersist.appliedContext === 'company'
                && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location.toLowerCase() === 'Dropped pin')) {
            setSearchHereFlag(true)
            setCurrentLocation(false)
        } if ((filter.filtersState[searchPersist.appliedContext]?.location?.location.length && searchPersist.appliedContext === 'project'
            && filter.filtersState[searchPersist.appliedContext]?.location?.location !== '') ||
            (filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location.length && searchPersist.appliedContext === 'company'
                && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location !== '')) {
            goBack()
            setSearchHereFlag(true)
            setMap([])
        } else {
            setCurrentLocation(false)
            setSearchHereFlag(false)
            searchHerePosition = defaultCenterPosition
        }
    }, [filter.filtersState[searchPersist.appliedContext]?.location, filter.filtersState[searchPersist.appliedContext]?.companyLocation])

    const goBack = () => {
        searchPersist.appliedContext === 'project' ? dispatch(updateShowSelectedCompanies(false)) : dispatch(updateShowSelectedProjects(false))
        // dispatch(updateSelectedSelectorControlValue(search?.selectedLeadsFlag?.length))
        dispatch(updateShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: false }))
        // dispatch(updateHierarchyBack(true))
        dispatch(updateProjectsForCompanyId([]))
        dispatch(rolesForProject([]))
        dispatch(updatePeopleProjectsList([]))
        dispatch(updatelistOfSelectedObjectIds({ leadsFlags: [], count: 0 }))
        dispatch(updateRemoveFilterToggle(true))
        dispatch(resetRelationalEntititySelectionCount())
        dispatch(updateObjectData(undefined))
        dispatch(updateCompanyDetail(undefined))
        dispatch(updateObjectId(undefined))
        dispatch(updateBackObjectId(undefined))
        dispatch(updateListSelection({ count: 0, entityType: 'groupedCompanies' }))
        if (!search.showProjectHierarchy && details.isProjectSelected) {
            const routingState = {
                ...location.state?.previousState,
                activeTab: '7'
            }
            navigate(`/app/company/0/${details.objectId}`, { state: routingState })
            dispatch(updateIsProjectSelected({ isProjectSelected: false }))
            dispatch(updateGroupCompanyProjectsFav([]))
            dispatch(updateGroupCompaniesDetails([]))
        }
        dispatch(updateClusterClickCount(0))
        dispatch(updateClusterList({ leadsFlags: [] }))
        dispatch(updateBackButtonCluster({ back: false }))
        dispatch(updateBackClusterList([]))
    }

    const setMap = (searchList) => {
        if (!isEmpty(mapContainer)) {
            onLoad(mapContainer, searchList)
        }
    }

    geolocate.on('geolocate', (e) => {
        if (!isEmpty(mapContainer)) {
            setCurrentLocation(false)
            setSearchHereFlag(false)
            setMapCurrentLocation(true)
            setSearchAtMyLocation(false)
            searchHerePosition = defaultCenterPosition
            mapContainer.flyTo({
                center: [e.coords.longitude, e.coords.latitude],
                zoom: 12.5
            })
        }
    })

    const onZoom = (map) => {
        if (typeof (map) != 'undefined' && map != null && typeof (map.style) != 'undefined' && typeof (map.transform) != 'undefined') {
            map.style.z >= 15.5 ? map.transform.pitch = 60 : map.transform.pitch = 0
        }
        setPopupClose(true)
        setPopupActive({})
    }

    const toggleStyle = () => {
        if (mapStyleURI === satellite) {
            setMapStyleURI(browser == "msie" ? newBasic : basic)
        } else {
            setMapStyleURI(satellite)
        }
    }

    const onLoad = (map, loadData) => {
        mapContainer = map
        if (!map.hasControl(geolocate)) {
            map.addControl(geolocate)
        }
        const { onLoad } = ifMyleadsExist ? myleads : search;
        geoJsonData = GeoJSON.parse(loadData, { Point: ['latitude', 'longitude'] });
        if (geoJsonData.features.length > 0) {
            let filterData = dataFilter(geoJsonData)
            companyData = filterData[0]
            projectData = filterData[1]
            applyFitbounds(geoJsonData, mapContainer)
        } else {
            companyData = []
            projectData = []
            if ((filter.filtersState[searchPersist.appliedContext]?.location?.location.length
                || filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location.length) && !searchAtMyLocation) {
                if ((searchPersist.appliedContext === 'project' && filter.filtersState[searchPersist.appliedContext]?.location?.location !== ''
                    && filter.filtersState[searchPersist.appliedContext]?.location?.longitude === 0
                    && filter.filtersState[searchPersist.appliedContext]?.location?.latitude === 0) ||
                    (searchPersist.appliedContext === 'company' && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location !== ''
                        && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.longitude === 0
                        && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.latitude === 0)) {
                    map.flyTo({
                        center: defaultCenterPosition,
                        zoom: 5
                    })
                } else if ((searchPersist.appliedContext === 'project' && filter.filtersState[searchPersist.appliedContext]?.location?.location !== ''
                    && filter.filtersState[searchPersist.appliedContext]?.location?.longitude !== 0
                    && filter.filtersState[searchPersist.appliedContext]?.location?.latitude !== 0) ||
                    (searchPersist.appliedContext === 'company' && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location !== ''
                        && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.longitude !== 0
                        && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.latitude !== 0)) {
                    map.flyTo({
                        center: searchPersist.appliedContext === 'project' ? [filter.filtersState[searchPersist.appliedContext]?.location?.longitude, filter.filtersState[searchPersist.appliedContext]?.location?.latitude]
                            : [filter.filtersState[searchPersist.appliedContext]?.companyLocation?.longitude, filter.filtersState[searchPersist.appliedContext]?.companyLocation?.latitude],
                        zoom: [10.0],
                        speed: 5.0
                    })
                    searchHerePosition = searchPersist.appliedContext === 'project' ? [filter.filtersState[searchPersist.appliedContext]?.location?.longitude, filter.filtersState[searchPersist.appliedContext]?.location?.latitude]
                        : [filter.filtersState[searchPersist.appliedContext]?.companyLocation?.longitude, filter.filtersState[searchPersist.appliedContext]?.companyLocation?.latitude]
                }
            }
        }
        return onLoad && onLoad(map);
    }

    const showRelationalData = (data) => {
        if (!isEmpty(mapContainer)) {
            if (!mapContainer.hasControl(geolocate)) {
                mapContainer.addControl(geolocate)
            }
            const { showRelationalData } = ifMyleadsExist ? myleads : search;
            let loadData = _.clone(data)
            let projectHierarchy = ifMyleadsExist ? myleads.showProjectHierarchy : search.showProjectHierarchy
            if (projectHierarchy && search?.projectHierarchicalRoles?.length || search.showCompanyHierarchy && search?.projectsForCompanyId) {
                let entityData = projectHierarchy ? search.projectRowData : search.showCompanyHierarchy ? search.companyDetails : {}
                loadData.push(entityData)
            }
            geoJsonData = GeoJSON.parse(loadData, { Point: ['latitude', 'longitude'] });
            if (geoJsonData.features.length > 0) {
                let filterData = dataFilter(geoJsonData)
                companyData = filterData[0]
                projectData = filterData[1]
                applyFitbounds(geoJsonData, mapContainer)
            } else {
                companyData = []
                projectData = []
                mapContainer.flyTo({
                    center: defaultCenterPosition,
                    zoom: 5
                })
            }
            return showRelationalData && showRelationalData();
        }
    }

    const onStyleLoad = (map) => {
        mapContainer = map;
        map.addControl(geolocate)
        const { onStyleLoad } = ifMyleadsExist ? myleads : search;
        var loadData;
        let projectHierarchy = ifMyleadsExist ? myleads.showProjectHierarchy : search.showProjectHierarchy
        loadData = search.backButtonCluster?.back ? search.clusterList.leadsFlags
            : projectHierarchy && search?.projectHierarchicalRoles?.length ? _.clone(search.projectHierarchicalRoles)
                : search.showCompanyHierarchy && search?.projectsForCompanyId ? _.clone(search.projectsForCompanyId)
                    : search.listOfSelectedObjectIds?.length ? search.listOfSelectedObjectIds
                        : search.groupCompanyProjects?.length ? search.groupCompanyProjects
                            : ifMyleadsExist ? props?.prevNextList === "myLeadsUpdates" ? myleads.updatesSearchList
                                : myleads.searchList : search.searchList;
        if (projectHierarchy && search?.projectHierarchicalRoles?.length || search.showCompanyHierarchy && search?.projectsForCompanyId) {
            let entityData = projectHierarchy ? search.projectRowData : search.showCompanyHierarchy ? search.companyDetails : entity
            loadData.push(entityData)
        }
        geoJsonData = GeoJSON.parse(loadData, { Point: ['latitude', 'longitude'] });

        map.resize()
        if (geoJsonData.features.length > 0) {
            let filterData = dataFilter(geoJsonData)
            companyData = filterData[0]
            projectData = filterData[1]
            applyFitbounds(geoJsonData, mapContainer)
        } else {
            companyData = []
            projectData = []
            if (searchPersist?.locationSearchObj?.[searchPersist.appliedContext]?.location) {
                if (searchPersist?.locationSearchObj?.[searchPersist.appliedContext]?.longitude === 0 && searchPersist?.locationSearchObj?.[searchPersist.appliedContext]?.latitude === 0) {
                    map.flyTo({
                        center: defaultCenterPosition,
                        zoom: 5
                    })
                } else if (searchPersist?.locationSearchObj?.[searchPersist.appliedContext]?.longitude && searchPersist?.locationSearchObj?.[searchPersist.appliedContext]?.latitude) {
                    map.flyTo({
                        center: [searchPersist?.locationSearchObj?.[searchPersist.appliedContext]?.longitude, searchPersist?.locationSearchObj?.[searchPersist.appliedContext]?.latitude],
                        zoom: [10.0],
                        speed: 5.0
                    })
                    searchHerePosition = [searchPersist?.locationSearchObj?.[searchPersist.appliedContext]?.longitude, searchPersist?.locationSearchObj?.[searchPersist.appliedContext]?.latitude]
                }
            }
        }
        return onStyleLoad && onStyleLoad(map);
    }

    const clusterMarkerProject = (coordinates, pointCount, getLeaves) => (
        <Marker
            key={coordinates.toString()}
            coordinates={coordinates}
            className={`project-cluster` +
                ` ${hoverInfoStatus && markerActiveFlag != undefined && filterID(markerActiveFlag.objectId, getLeaves) ? 'active' : ''}`}
            onClick={(e) => clusterClick(e, coordinates, pointCount, getLeaves, 'project')}
        >
            <div>{pointCount}</div>
        </Marker>
    )

    const clusterMarkerCompany = (coordinates, pointCount, getLeaves) => (
        <Marker
            key={coordinates.toString()}
            coordinates={coordinates}
            className={`company-cluster` +
                ` ${hoverInfoStatus && markerActiveFlag != undefined && filterID(markerActiveFlag.objectId, getLeaves) ? 'active' : ''}`}
            onClick={(e) => clusterClick(e, coordinates, pointCount, getLeaves, 'company')}
        >
            <div>{pointCount}</div>
            <div className={`pro-related-com ${search.showProjectHierarchy || search.showCompanyHierarchy || myleads.showProjectHierarchy || search.listOfSelectedObjectIds?.length ? 'show' : 'hide'}`}>{getLeaves()[0].props['data-feature'].properties.name} &nbsp; +{[Number(pointCount) - 1]}</div>
        </Marker>
    )

    const popupClick = (e, popup) => {
        e.preventDefault()
        e.stopPropagation()
        popupRedirectClick(popup, navigate, props, search, ifMyleadsExist, ifMyleadsHierarchyExist, location)
    }

    const closePopup = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setPopupClose(true)
        setPopupActive({})
    }

    const clusterClick = (e, coordinates, total, getLeaves, type) => {
        e.preventDefault()
        e.stopPropagation();
        clusterData = { features: [], type: 'FeatureCollection', clusterClickStatus: true }
        let clusterListObj = { isShowMoreActive: true, leadsFlags: [], temp: {} }
        getLeaves().filter(function (e, key) {
            clusterData.features.push(e.props['data-feature']);
            clusterListObj.leadsFlags.push(e.props['data-feature'].properties);
            clusterListObj.leadsFlags[key] = { ...clusterListObj.leadsFlags[key], longitude: e.props['data-feature'].geometry.coordinates[0] }
            clusterListObj.leadsFlags[key] = { ...clusterListObj.leadsFlags[key], latitude: e.props['data-feature'].geometry.coordinates[1] }
        })
        modifyCoordinates(clusterData)
        modifyClusterCoordinates(clusterListObj)

        dispatch(updateClusterList(clusterListObj))
        let backClusterListData = _.clone(search.backClusterList)
        backClusterListData.push(clusterListObj)
        dispatch(updateBackClusterList(backClusterListData))
        if (type == 'company') {
            companyData = clusterData;
            projectData = { features: [], type: 'FeatureCollection' }
        } else {
            projectData = clusterData;
            companyData = { features: [], type: 'FeatureCollection' }
        }
        geoJsonData = GeoJSON.parse(clusterListObj.leadsFlags, { Point: ['latitude', 'longitude'] });
        applyFitbounds(geoJsonData, mapContainer, null)
        dispatch(updateBackButtonCluster({ back: true, status: false, relationBack: false }))
        dispatch(updateClusterClickCount(search.clusterClickCount + 1))
        dispatch(updateNavigationBack('clusterClick'))
        setMarkerActiveFlag({})
    }

    const markerClick = (e, coordinates, feature) => {
        e.preventDefault()
        e.stopPropagation()
        setPopup({ coordinates, feature })
        setPopupClose(false)
        setPopupActive({ coordinates })
        setHoverInfoStatus(false)
        setMarkerActiveFlag({})
    };

    const showRelatedCompany = (feature, event) => {
        event.preventDefault()
        let dataObject = feature.properties
        let coordinates = feature.geometry.coordinates
        dataObject["longitude"] = coordinates === '' ? entity.longitude : coordinates[0]
        dataObject["latitude"] = coordinates === '' ? entity.latitude : coordinates[1]
        setEntity(dataObject)
        if (event.target.checked) {
            handleRolesForProjectId(dataObject)
        } else {
            setEntity({})
            companyData.features.length = projectData.features.length = 0
            dispatch(rolesForProject([]))
            dispatch(updateObjectData(undefined))
            dispatch(updateNavigationBack(search.clusterList.leadsFlags?.length > 0 ? 'clusterClick' : ''))
            if (ifMyleadsExist) {
                dispatch(updateMyLeadsShowHierarchy({ showProjectHierarchy: false }))
            } else {
                dispatch(updateShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: false }))
            }
        }
    }

    const handleRolesForProjectId = (data) => {
        fetchRolesForProjects(data.project_id, dispatch)
        dispatch(updateObjectData(data))
        dispatch(updateNavigationBack('relationClick'))
        if (ifMyleadsExist) {
            dispatch(updateMyLeadsShowHierarchy({ showProjectHierarchy: true }))
        } else {
            dispatch(updateShowHierarchy({ showProjectHierarchy: true, showCompanyHierarchy: false }))
        }
    }

    const showRelatedProject = (feature, event) => {
        event.preventDefault()
        let dataObject = feature.properties
        let coordinates = feature.geometry.coordinates
        dataObject["longitude"] = coordinates === '' ? entity.longitude : coordinates[0]
        dataObject["latitude"] = coordinates === '' ? entity.latitude : coordinates[1]
        setEntity(dataObject)
        if (event.target.checked) {
            handleRolesForCompanyId(dataObject)
        } else {
            setEntity({})
            companyData.features.length = projectData.features.length = 0
            dispatch(updateProjectsForCompanyId([]))
            dispatch(updateRemoveFilterToggle(false))
            dispatch(updateObjectId(undefined))
            if (ifMyleadsExist) {
                dispatch(updateMyLeadsShowHierarchy({ showProjectHierarchy: false }))
            } else {
                dispatch(updateShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: false }))
            }
        }
    }

    const handleRolesForCompanyId = (dataObject) => {
        fetchRolesForCompanyId(dataObject.company_id, search, searchPersist, dispatch)
        dispatch(updateRemoveFilterToggle(true))
        dispatch(updateObjectId(dataObject.company_id))
        dispatch(updateNavigationBack('relationClick'))
        if (ifMyleadsExist) {
            dispatch(updateMyLeadsShowHierarchy({ showProjectHierarchy: false }))
        } else {
            dispatch(updateShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: true }))
        }
    }

    const onMoveStart = (map) => {
        setDragStart(true)
    }

    const searchHereList = () => {
        let lat = mapContainer.getCenter().wrap().lat
        let lng = mapContainer.getCenter().wrap().lng
        searchHerePosition = mapContainer.getCenter()
        setSearchHereFlag(mapCurrentLocation ? false : true)
        setCurrentLocation(false)
        let payload = {
            value: [],
            dataObj: [],
            active: false,
            keyname: searchPersist.appliedContext === 'project' ? 'projectTown' : 'companyTown'
        }
        dispatch(updateFilterText(payload))
        payload.keyname = searchPersist.appliedContext === 'project' ? 'projectPostcode' : 'companyPostcode'
        dispatch(updateFilterText(payload))
        var locationData = {
            location: mapCurrentLocation ? 'Current location' : 'Dropped pin',
            latitude: lat ? parseFloat(lat) : lat,
            longitude: lng ? parseFloat(lng) : lng,
            radius: filter.filtersState[searchPersist.appliedContext]?.location?.radius ? filter.filtersState[searchPersist.appliedContext]?.location.radius : settings.preferences.default_distance,
            active: true,
            keyname: searchPersist.appliedContext === 'project' ? "location" : "companyLocation"
        }
        dispatch(updateAdvanceLocation(locationData))
        let sort = searchPersist.appliedContext === 'project' ? 'project_distance' : 'company_distance'
        dispatch(updateSort(searchPersist.appliedContext === 'project' ? 'project_distance' : 'company_distance'))
        let updatedSortOrderObj = _.cloneDeep(searchPersist.defaultSort);
        updatedSortOrderObj[searchPersist.appliedContext] = {
            label: fetchSortLabel(sort),
            value: sort,
        };
        dispatch(updateSortSearch(updatedSortOrderObj));
        dispatch(updateOffsetLimit({ offset: 0, records: 50 }));
        dispatch(updateSearchHere(true))
        dispatch(updateHierarchyBack(false))
        setDragStart(false)
        setMapCurrentLocation(false)
        setSearchAtMyLocation(mapCurrentLocation ? true : false)
    }

    return (
        <>
            <Map
                style={mapStyleURI}
                onStyleLoad={onStyleLoad}
                onDrag={onMoveStart}
                onZoom={onZoom}
                flyToOptions={flyToOptions}
                movingMethod="jumpTo"
                trackResize={true}
                failIfMajorPerformanceCaveat={true}
                refreshExpiredTiles={false}
                className={searchListData.length <= 0 || leadsData?.length <= 0 ? 'pull-left mapboxgl-map' : 'mapboxgl-map'}
            >
                <div className="cd-mapbox-toolbar">
                    {
                        !searchPersist.isListView && filter.isFilterActive == false ?
                            (<div className="hori-placeholder-tour right-zero bottom-zero" data-intro={TOURPROJECTMAPVIEW.maptoolbar.intro} data-position={TOURPROJECTMAPVIEW.maptoolbar.position} data-class={TOURPROJECTMAPVIEW.maptoolbar.class}>&nbsp;</div>) :
                            ('')
                    }
                </div>
                <ZoomControl style={props.prevNextList === 'searchList' ? { top: 23 } : { top: 65 }} className={`${sidebarVisible ? 'zoomCtrlclass' : ''}`} position="top-right" />
                <a data-tooltip-id="map-style"
                    data-tooltip-hidden={!settings.preferences.hint_text}
                    onClick={toggleStyle.bind(this)} className={`map-style-toggle ${mapStyleURI === satellite ? 'basic' : 'satellite'}`}></a>
                <ReactTooltip id="map-style" place="left" type='dark' effect="solid">
                    <p className="tool-tip-sidebar">Switch map layer</p>
                </ReactTooltip>
                {companyData && companyData.features ?
                    <Cluster
                        ClusterMarkerFactory={clusterMarkerCompany}
                        radius={radius}
                        maxZoom={maxZoom}
                        zoomOnClickPadding={setPadding}
                        zoomOnClick={true}
                    >
                        {
                            companyData && companyData.features && companyData.features.map((feature, key) =>
                                <Marker
                                    key={key}
                                    className={getColor(feature.properties.objectType) +
                                        ` ${feature.geometry.coordinates == popupActive.coordinates ? 'active' : ''}` +
                                        `${feature.geometry.coordinates[0] == hoverList[0] && feature.geometry.coordinates[1] == hoverList[1] && hoverList[3] == "company" ? 'active' : ''}`}
                                    coordinates={feature.geometry.coordinates}
                                    data-feature={feature}
                                    onClick={(e) => markerClick(e, feature.geometry.coordinates, feature)}
                                >
                                    <div className={`hover-com-info ${feature.properties.objectId == hoverList[2] && feature.geometry.coordinates[0] == hoverList[0] && feature.geometry.coordinates[1] == hoverList[1] && hoverInfoStatus == true ? 'show' : 'hide'}`}>{feature.properties.name}</div>
                                    <div className={`pro-related-com ${search.showProjectHierarchy || search.showCompanyHierarchy || myleads.showProjectHierarchy || search.listOfSelectedObjectIds?.length ? 'show' : 'hide'}`}>{feature.properties.name}</div>
                                </Marker>
                            )
                        }
                    </Cluster> : ('')}
                {projectData && projectData.features ?
                    <Cluster
                        ClusterMarkerFactory={clusterMarkerProject}
                        radius={radius}
                        maxZoom={maxZoom}
                        zoomOnClickPadding={setPadding}
                        zoomOnClick={true}
                    >
                        {
                            projectData && projectData.features && projectData.features.map((feature, key) =>
                                <Marker
                                    key={key}
                                    className={getColor(feature.properties.objectType) +
                                        `${feature.geometry.coordinates === popupActive.coordinates ? ' active' : ''}` +
                                        `${feature.geometry.coordinates[0] === hoverList[0] && feature.geometry.coordinates[1] === hoverList[1] && hoverList[3] === "project" ? ' active' : ''}` +
                                        `${markerActiveFlag !== undefined && feature.properties.objectId === markerActiveFlag.objectId ? ' active' : ''}`}
                                    coordinates={feature.geometry.coordinates}
                                    data-feature={feature}
                                    onClick={(e) => markerClick(e, feature.geometry.coordinates, feature)}
                                >
                                    <div className={`hover-pro-info ${feature.properties.objectId === hoverList[2] && feature.geometry.coordinates[0] === hoverList[0] && feature.geometry.coordinates[1] === hoverList[1] && hoverInfoStatus === true ? 'show' : 'hide'}`}>{feature.properties.name}</div>
                                    <div className={`pro-related ${search.showProjectHierarchy || search.showCompanyHierarchy || myleads.showProjectHierarchy || search.listOfSelectedObjectIds?.length ? 'show' : 'hide'}`}>{feature.properties.name}</div>
                                </Marker>
                            )
                        }
                    </Cluster> : ('')}
                <Marker
                    coordinates={searchHerePosition}>
                    <div className={`${searchHereFlag || currentLocation ? 'mapboxgl-user-searchHere-location-dot-custom mapboxgl-marker' : 'hide'}`}></div>
                    <div className={`${currentLocation ? 'mapboxgl-user-location-dot-custom mapboxgl-marker' : 'hide'}`}></div>
                </Marker>
                {popup && popup.feature && (
                    <Popup className={`${popupClose === false ? '' : 'closePopup'} ${!popup.feature.properties.read ? 'read-popup' : ''}`}
                        offset={[0, -55]}
                        coordinates={popup.coordinates}>
                        <a onClick={(event) => popupClick(event, popup.feature)} href={`${popup.feature.properties.objectType == "company" ? '/app/company/' + `${popup.feature.properties.reno == undefined ? 0 : popup.feature.properties.reno}` + '/' + `${popup.feature.properties.objectId ? popup.feature.properties.objectId : popup.feature.properties.id}` : '/app/project/' + `${popup.feature.properties.objectId ? popup.feature.properties.objectId : popup.feature.properties.id}`}`} >
                            <div className="result-row-icon-placeholder">
                                <span className={`mb-0 ${popup.feature.properties.objectType === "project" ? 'icon-project' : 'icon-company'}`}></span>
                                {popup.feature.properties.fav == true ? (
                                    <span className="icon-unfavourite"></span>) :
                                    (<span></span>)
                                }

                            </div>
                            <div className="result-row-overview">
                                <h5 className="result-title-name mb-1">{popup.feature.properties.name}</h5>
                                <div className={`sub-resultdetails-content ${popup.feature.properties.objectType == 'project' ? 'show' : 'hide'}`}>
                                    {isValidText(popup.feature.properties.project_scheme)
                                        ? (<p className="mb-1 small">
                                            <strong>Catering Scheme:</strong>
                                            {popup.feature.properties.project_scheme}
                                        </p>) : ('')}

                                    <div className={`result-client-name-value show`}>
                                        {popup.feature.properties.role_11_company_names
                                            ? (<span className="rs-client-name">
                                                {popup.feature.properties.role_11_company_names}
                                            </span>) : (<span><strong>Client:</strong></span>)}

                                        {isValidText(popup.feature.properties.project_value) ?
                                            (popup.feature.properties.project_value && popup.feature.properties.project_value == '-') ? ('') : (<span className={(popup.feature.properties.project_planning_stage || popup.feature.properties.project_contract_stage) ? "rs-value" : ""}>{displayCurrency(popup.feature.properties.project_value, popup.feature.properties.project_value_estimated)}</span>) : ('')
                                        }

                                        <span className="rs-stages">
                                            <strong>Stage:</strong>
                                            {isValidText(popup.feature.properties.project_planning_stage_display) && isValidText(popup.feature.properties.project_contract_stage_display) ?
                                                (<span>{popup.feature.properties.project_planning_stage_display == "-" ? null : popup.feature.properties.project_planning_stage_display + ", "}</span>) :
                                                (<span>{popup.feature.properties.project_planning_stage_display == "-" ? null : popup.feature.properties.project_planning_stage_display}</span>)}
                                            {isValidText(popup.feature.properties.project_contract_stage_display) ? (<span>{popup.feature.properties.project_contract_stage_display}</span>) : ('')}
                                        </span>
                                    </div>
                                </div>
                                <div className={`result-client-name-value show`}>
                                    {isValidText(popup.feature.properties.role_desc) ?
                                        (<div className="sub-resultdetails-content">
                                            <div className="rs-roles">
                                                <strong>Roles:</strong>
                                                {getRoles(popup.feature.properties.role_desc && popup.feature.properties.role_desc.trim().replace(/,*$/, ''))}
                                            </div>
                                        </div>) : ""
                                    }
                                </div>
                                {popup.feature.properties.objectType == "company" && !popup.feature.properties.desc1 ?
                                    (
                                        <div>{popup.feature.properties.company_address1 || popup.feature.properties.company_address2 || popup.feature.properties.address3 || popup.feature.properties.company_address4 || popup.feature.properties.company_postcode ?
                                            (
                                                <span className="rs-address">{popup.feature.properties.company_address1 && popup.feature.properties.company_address1 + ", "} {popup.feature.properties.company_address2 && popup.feature.properties.company_address2 + ", "} {popup.feature.properties.address3 && popup.feature.properties.address3 + ", "} {popup.feature.properties.company_address4 && popup.feature.properties.company_address4 + ", "} {popup.feature.properties.company_postcode && popup.feature.properties.company_postcode}</span>
                                            ) : ("")
                                        }
                                            {
                                                popup.feature.properties.company_phone ?
                                                    (
                                                        <span className="rs-address rs-phone">{popup.feature.properties.company_phone}
                                                            {
                                                                popup.feature.properties.company_phone_tps ?
                                                                    (
                                                                        <span className="tps-badge">TPS</span>
                                                                    )
                                                                    : ("")
                                                            }
                                                        </span>
                                                    )
                                                    : ("")
                                            }
                                            {
                                                popup.feature.properties.company_website ? (<span className="rs-phone">
                                                    {popup.feature.properties.company_website}
                                                </span>) : ("")
                                            }   </div>) : ("")
                                }
                                {
                                    (<div>
                                        {isValidText(popup.feature.properties.project_site3) ? (<span className={`src-location-placeholder`}>{popup.feature.properties.project_site3}</span>) : ('')}
                                        {(searchPersist?.locationSearchObj[activeFilterValue]?.location !== '' && searchPersist?.defaultSort[activeFilterValue]?.label === 'nearest')
                                            || (searchPersist?.defaultSort[activeFilterValue]?.label === 'relevance') ? (
                                            popup.feature.properties?.project_distance ?
                                                (<span className='src-date'><span className="src-miles"><Icon color="#292B2C" size={20} icon='icon-gen-arrow-cross' className="me-1" />{convertMilesToYards(popup.feature.properties.project_distance)}</span></span>)
                                                : popup.feature.properties?.company_distance ?
                                                    (<span className='src-date'><span className="src-miles"><Icon color="#292B2C" size={20} icon='icon-gen-arrow-cross' className="me-1" />{convertMilesToYards(popup.feature.properties.company_distance)}</span></span>)
                                                    : ""
                                        ) : searchPersist?.defaultSort[activeFilterValue]?.label === 'start date' ? (<span className='src-date'><span className={`rs-start-date`}>{getFormatedStartOrEndDate(popup.feature.properties.project_start_display)}</span></span>)
                                            : searchPersist?.defaultSort[activeFilterValue]?.label === 'end date' ? (<span className='src-date'><span className={`rs-end-date`}>{getFormatedStartOrEndDate(popup.feature.properties.project_finish_display)}</span></span>)
                                                : searchPersist?.defaultSort[activeFilterValue]?.label === 'Projects' ? (<span className='src-date'><span className="src-count">{popup.feature.properties.count_project_id}</span></span>)
                                                    : searchPersist?.defaultSort[activeFilterValue]?.label === 'Value' ? activeFilterValue === 'company' ? (<span className='src-date'><span className="src-value">{displayCurrency(popup.feature.properties.sum_project_value)}</span></span>)
                                                        : <span className="src-date">
                                                            <span className="src-latest">{getFormatedDate(popup.feature.properties.project_last_published, 'DD-MMM-YYYY')}</span>
                                                        </span>
                                                        : <span className="src-date">
                                                            <span className="src-latest">{getFormatedDate(popup.feature.properties.max_project_last_published ? popup.feature.properties.max_project_last_published : popup.feature.properties.project_last_published, 'DD-MMM-YYYY')}</span>
                                                        </span>}
                                    </div>
                                    )
                                }
                            </div></a>
                        {ifMyleadsExist ?
                            searchPersist.leadsAppliedContext[myleadsTab] === 'project' && (!myleads.showProjectHierarchy || myleads.showProjectHierarchy && entity?.objectType === 'project') && popup.feature.properties.objectType == "project" && <div className={`map-switch show`}>
                                <Input id="switch-relatedCompanies" type="checkbox" checked={myleads.showProjectHierarchy} onChange={(e) => showRelatedCompany(popup.feature, e)} className="cmn-toggle cmn-toggle-round" />
                                <Label for="switch-relatedCompanies"></Label>
                                <span>Show companies</span>
                            </div>
                            :
                            searchPersist.appliedContext === 'project' ? (!search.showProjectHierarchy || search.showProjectHierarchy && entity?.objectType === 'project') && popup.feature.properties.objectType == "project" && <div className={`map-switch show`}>
                                <Input id="switch-relatedCompanies" type="checkbox" checked={search.showProjectHierarchy} onChange={(e) => showRelatedCompany(popup.feature, e)} className="cmn-toggle cmn-toggle-round" />
                                <Label for="switch-relatedCompanies"></Label>
                                <span>Show companies</span>
                            </div> :
                                (!search.showCompanyHierarchy || search.showCompanyHierarchy && entity?.objectType === 'company') && popup.feature.properties.objectType == "company" && <div className={`map-switch show`}>
                                    <Input id="switch-relatedCompanies" type="checkbox" checked={search.showCompanyHierarchy} onChange={(e) => showRelatedProject(popup.feature, e)} className="cmn-toggle cmn-toggle-round" />
                                    <Label for="switch-relatedCompanies"></Label>
                                    <span>Show Projects</span>
                                </div>
                        }
                        <Button className="btn-pop-cancel btn btn-secondary" onClick={(event) => closePopup(event)}>Cancel</Button>
                    </Popup>
                )}
                {!ifMyleadsExist ? <div className="map-search-here">
                    {
                        searchPersist.isListView === false && filter.isFilterActive == false ?
                            (<div className="hori-placeholder-tour top-zero left-right-zero" data-intro={TOURPROJECTMAPVIEW.searchhere.intro} data-position={TOURPROJECTMAPVIEW.searchhere.position} data-class={TOURPROJECTMAPVIEW.searchhere.class}>&nbsp;</div>) :
                            ('')
                    }
                    <button
                        onClick={searchHereList}
                        className={`${dragStart || mapCurrentLocation ? `visible btn primary-btn ${search?.searchList?.length === 0 ? 'map-search-no-result' : ''}` : 'hidden'}`}>
                        {mapCurrentLocation ? 'Search at my location...' : 'Search Here...'}
                    </button>
                </div> : ('')}
            </Map>

        </>

    )

}

export default MapPanel
