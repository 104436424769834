import React, { useState, useRef, useEffect } from 'react';
import { Col, Dropdown, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { EVENT_SORT_ORDER, EVENT_TYPE, EVENT_STATUS } from '../../utils/settings';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { deleteCalendarEvent, deleteEvent, fetchCalendarEvents } from './apiclient';
import { updateCalendarOffsetLimitValue, updateIsRefine, updateSelectedSortOrder, updateSelectedStatus, updateSelectedType } from './reducer';

const CalendarHeader = (props) => {
    
    const dispatch = useDispatch();
    const calendarEventState = useSelector(state => state.calendar)
    const userData = useSelector(state=> state.user.user)

    const [typeMenuOpen, setTypeMenuOpen] = useState(false);
    const [statusMenuOpen, setStatusMenuOpen] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [dropDownValueSortOrder, setDropDownValueSortOrder] = useState(calendarEventState?.selectedSortOrder ? calendarEventState?.selectedSortOrder : EVENT_SORT_ORDER[0]);
    const [dropDownTypeValue, setDropDownTypeValue] = useState(calendarEventState?.selectedType ? calendarEventState?.selectedType : null);
    const [dropDownStatusValue, setDropDownStatusValue] = useState(calendarEventState?.selectedStatus ? calendarEventState?.selectedStatus : null);
    const [payload, setPayload] = useState({})
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);


    const toggleMenu = (e, menu) => {
        e.stopPropagation();
        if (menu === 'typeMenu') {
            setTypeMenuOpen(true);
        } else {
            setStatusMenuOpen(true);
        }
    };

    const closeMenu = (menu) => {
        if (menu === 'typeMenu') {
            setTypeMenuOpen(false);
        } else {
            setStatusMenuOpen(false);
        }
    };


    const toggleDropdown = () => {
        setDropDownOpen(!dropDownOpen);
    };

    const handleDropdownLabel = (optionItem) => {
        setDropDownValueSortOrder(optionItem);
    };

    const handleTypeClick = (type) => {
        setDropDownTypeValue(type)
    };

    const handleStatusClick = (status) => {
        setDropDownStatusValue(status)
    };

    const handleDelete = (payload) => {
        if (payload.eventId !== 0) {
            setShowDeleteAlert(true)
            setPayload(payload)
        }
    }

    const undoDelete = () => {
        setShowDeleteAlert(false)
    }

    const handleRefine = () => {
        dispatch(updateSelectedSortOrder(dropDownValueSortOrder))
        dispatch(updateSelectedType(dropDownTypeValue))
        dispatch(updateSelectedStatus(dropDownStatusValue))
        dispatch(updateIsRefine(true))
        dispatch(updateCalendarOffsetLimitValue({ offset: 0, records: 50 }))
    }

    useEffect(() => {
        if (showDeleteAlert) {
            const timer = setTimeout(() => {
                deleteCalendarEvent({ ids: calendarEventState?.selectedEvents }, calendarEventState, dispatch)
                setShowDeleteAlert(false)
                setPayload({})
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [showDeleteAlert, payload])

    useEffect(() => {
        if (calendarEventState?.selectedSortOrder !== null) {
            setDropDownValueSortOrder(calendarEventState?.selectedSortOrder)
        } else {
            setDropDownValueSortOrder(EVENT_SORT_ORDER[0])
        }

        if (calendarEventState?.selectedType !== null) {
            setDropDownTypeValue(calendarEventState?.selectedType)
        } else {
            setDropDownTypeValue(null)
        }

        if (calendarEventState?.selectedStatus !== null) {
            setDropDownStatusValue(calendarEventState?.selectedStatus)
        } else {
            setDropDownStatusValue(null)
        }
    }, [calendarEventState?.selectedSortOrder, calendarEventState?.selectedType, calendarEventState?.selectedStatus ])
    

    return (
        <>
            <div className={"delete-alert " + (showDeleteAlert ? 'show' : 'hide')}>
                <span>Event deleted</span><button onClick={undoDelete}>Undo</button>
            </div>
            <div>
                <Row className="page-title-bar">
                    <Col className="py-2">
                        <h2 className="h5 pl-3 mb-0">Calendar</h2>
                        <p class="result-count pl-3">{`${calendarEventState?.eventList?.length} of ${calendarEventState?.calendarEventListCount} results`}</p>
                    </Col>
                </Row>
                <div className="pl-4 mr-auto d-flex flex-wrap event-filter">
                    <div className="pr-0 mr-2 mb-2">
                        <label>Sort order</label>
                        <Dropdown isOpen={dropDownOpen} toggle={toggleDropdown}>
                            <DropdownToggle caret>{dropDownValueSortOrder}</DropdownToggle>
                            <DropdownMenu>
                                {EVENT_SORT_ORDER.map((optionItem) => {
                                    return <DropdownItem key={optionItem} onClick={() => handleDropdownLabel(optionItem)}>{optionItem}</DropdownItem>
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="pr-0 mr-2 mb-2">
                        <div className="multiselect-container" onClick={(e) => toggleMenu(e, 'typeMenu')}>
                            <label>Type</label>
                            <ReactMultiSelectCheckboxes
                                defaultValue={dropDownTypeValue}
                                options={EVENT_TYPE}
                                onChange={(e) => handleTypeClick(e)}
                                menuIsOpen={typeMenuOpen}
                                onBlur={() => closeMenu('typeMenu')}
                            />
                        </div>
                    </div>
                    <div className="pr-0 mr-2 mb-2">
                        <div className="multiselect-container" onClick={(e) => toggleMenu(e, 'statusMenu')}>
                            <label>Status</label>
                            <ReactMultiSelectCheckboxes
                                defaultValue={dropDownStatusValue}
                                options={EVENT_STATUS}
                                onChange={(e) => handleStatusClick(e)}
                                menuIsOpen={statusMenuOpen}
                                onBlur={() => closeMenu('statusMenu')}
                            />
                        </div>
                    </div>
                    <div className="ps-0 ms-2 mb-2 filter-button-cnt">
                        <button className="btn btn-primary ms-3" onClick={handleRefine}>Refine</button>
                        {/* <button className="btn delete-selected mr-2">Delete Selected</button> */}
                        {calendarEventState?.selectedEvents?.length > 0 ? <button className="btn delete-selected ms-2" onClick={handleDelete}>Delete Selected</button> : ("")}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CalendarHeader;

