import React from 'react'
import ReactDOM from 'react-dom/client';
import { messageAlert } from '.'
import { signOutUser } from '../components/login/apiclient';
import NotFoundPage from '../pages/notfound';

export function handleError(error, dispatch){
  // Redirect the navigation to login page incase of 401 which is session timeout
  if(error.response && error.response.status === 401) {
    // Update the react state with alter message
    dispatch(signOutUser())
    localStorage.clear()
    messageAlert(dispatch, 'Your session has expired #Please sign in again', 'danger')
  }
  if(error.response && error.response.status === 404){
    ReactDOM.createRoot(document.body).render(<NotFoundPage />)
  }
}
