import { sha256 } from 'js-sha256';
import { messageAlert } from '../../utils';
import getAxios from '../../utils/baseApi';
import { signInUser } from '../login/apiclient';

export const masqueradeUser = (token, context, dispatch, navigate) => {
    var axios = getAxios()
    axios.get('/auth/masquerade/' + token)
    .then(function (response) {
        let responseJson = {
            user: response.data.data
        }
        responseJson['user']['is_cars_user'] = true
        context.dispatchMasqueradeEvent('MASQUERADE_USER_LIST', responseJson)
        if('redirect_to' in response.data.data && 'sector_no' in response.data.data && 'web_username' in response.data.data && response.data.data.web_username){
            let user = {
                username: response.data.data.web_username,
                password: sha256(response.data.data.web_username),
                rememberMe: false,
                stno: response.data.data.sector_no,
                isMasqueradeUser: true,
                masqueraded_by: response.data.data.cars_user_no,
                redirectTo: response.data.data.redirect_to
            }
            signInUser(user, navigate, dispatch, {}, {}, null, true)
            context.dispatchMasqueradeEvent('REDIRECT_USER', true)
        } else {
            context.dispatchMasqueradeEvent('REDIRECT_USER', false)
        }
        context.dispatchMasqueradeEvent('MASQUERADE_USER', false)
        return response
    })
    .catch(function (error) {
        if(error.response.status === 401){
            messageAlert(dispatch, "Reusing the URL is forbidden, please click on Masquerade again.", 'danger')
            navigate('/')
        }
    })  
}