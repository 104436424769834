import React from 'react'

export const dataFilter = (inputData) => {
  if (inputData != undefined) {
    let companyFilter = Object.assign({}, inputData)
    let projectFilter = Object.assign({}, inputData)
    if (companyFilter.features) {
      companyFilter.features = companyFilter.features.filter(function (e) {
        if (e.properties["objectType"] === "company") {
          companyFilter.features = e.properties["objectType"] === "company"
          companyFilter.features = e.geometry.coordinates && (e.geometry.coordinates[0] != -1 && e.geometry.coordinates[0] != 0)
          companyFilter.features = e.geometry.coordinates && (e.geometry.coordinates[1] != -1 && e.geometry.coordinates[1] != 0)
          return companyFilter.features;
        }
      })
    }

    if (projectFilter.features) {
      projectFilter.features = projectFilter.features.filter(function (e) {
        if (e.properties["objectType"] === "project") {
          projectFilter.features = e.properties["objectType"] === "project"
          projectFilter.features = e.geometry.coordinates && (e.geometry.coordinates[0] != -1 && e.geometry.coordinates[0] != 0)
          projectFilter.features = e.geometry.coordinates && (e.geometry.coordinates[1] != -1 && e.geometry.coordinates[1] != 0)
          return projectFilter.features;
        }
      })
    }
    let FilterData = [companyFilter, projectFilter]
    return FilterData
  }
}

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export const applyFitbounds = (clusterData, mapContainer) => {
  var fitBox
  if (clusterData != undefined && clusterData.features.length > 0) {
    let clusterLongitude = []
    let clusterLatitude = []

    clusterData.features.filter(function (f) {
      if (f.geometry.coordinates && (f.geometry.coordinates[0] != -1 && f.geometry.coordinates[0] != 0)) {
        clusterLongitude.push(f.geometry.coordinates[0])
      }
    });

    clusterData.features.filter(function (f) {
      if (f.geometry.coordinates && (f.geometry.coordinates[1] != -1 && f.geometry.coordinates[1] != 0)) {
        clusterLatitude.push(f.geometry.coordinates[1])
      }
    });
    fitBox = [[(Math.min(...clusterLongitude)), (Math.min(...clusterLatitude))], [(Math.max(...clusterLongitude)), (Math.max(...clusterLatitude))]]
    fitBox[0][0] == Infinity ? fitBox = [[-4.288361, 50.795279], [-0.02419, 56.463158]] : fitBox = fitBox
    if (clusterLongitude[0] != [-1]) {
      if (mapContainer.fitBounds !== undefined) {
        mapContainer.fitBounds(fitBox, {
          speed: 4,
          padding: {
            top: 60,
            bottom: 30,
            left: 30,
            right: 30
          }
        });
      }
    }
  }
}

export const getRoles = (rolesValue) => {
  var data = []
  var roles = []
  if (rolesValue != undefined) {
    data = rolesValue.split(',')
    let rolesToDisplay = data.slice(0, 3)
    for (let i = 0; i < rolesToDisplay.length; i++) {
      roles.push(<span key={`roles${i}`}>{rolesToDisplay[i]}</span>)
    }
    if (data.length - 3 > 0) {
      roles.push((<span className="roles-count"><span className="plusIcon">+</span>{data.length - 3 + " more"}</span>))
    }
    return roles
  }
}

export const modifyCoordinates = (clusterData) => {
  let modifyData = Object.assign({}, clusterData)
  let newData = Object.assign({}, clusterData)
  modifyData.features = modifyData.features.filter(function (e, key) {
    for (var i = 0; i < newData.features.length; i++) {
      if (e.geometry.coordinates[0] == newData.features[i].geometry.coordinates[0] && key != i) {
        clusterData.features[i].geometry.coordinates[0] = newData.features[i].geometry.coordinates[0] + 0.00002
      }
    }
  })
  return clusterData;
}

export const modifyClusterCoordinates = (clusterData) => {
  let modifyData = Object.assign({}, clusterData)
  let newData = Object.assign({}, clusterData)
  modifyData.leadsFlags = modifyData.leadsFlags.filter(function (e, key) {
    for (var i = 0; i < newData.leadsFlags.length; i++) {
      if (e.longitude == newData.leadsFlags[i].longitude && key != i) {
        clusterData.leadsFlags[i].longitude = newData.leadsFlags[i].longitude + 0.00002
      }
    }
  })
  return clusterData;
}

export const getColor = (input) => {
  if (input == "company") {
    return "map-company"
  } else {
    return "map-project"
  }
}

export const popupRedirectClick = (popup, navigate, props, search, ifMyleadsExist, ifMyleadsHierarchyExist, location) => {
  let ID = popup.properties.objectId ? popup.properties.objectId : popup.properties.id
  if (popup.properties.objectType === "company") {
    let routingState = {
      prevNextList: props.prevNextList,
      index: ['searchList', 'selectedSearchList', 'companyHierarchicalSearchList'].includes(props.prevNextList) || !ifMyleadsHierarchyExist ? 0
        : search.projectHierarchicalRoles.findIndex(obj => obj.objectId === ID),
      list: props.prevNextList === 'searchList' ? [popup.properties]
        : props.prevNextList === 'selectedSearchList' ? [popup.properties]
          : props.prevNextList === 'companyHierarchicalSearchList' ? [popup.properties]
            : props.prevNextList === 'projectHierarchicalSearchList' || ifMyleadsHierarchyExist ? search.projectHierarchicalRoles
              : ifMyleadsExist ? [popup.properties] : [],
      activeTab: props.prevNextList === 'myLeadsUpdates' ? '5' : '2',
      from: 'mapClick'
    }
    navigate(`/app/company/0/${ID}`, { state: routingState });
  } else {
    let routingState = {
      prevNextList: props.prevNextList,
      showMoreResult: true,
      index: ['searchList', 'selectedSearchList', 'projectHierarchicalSearchList', 'groupedCompanyProjects'].includes(props.prevNextList) || ifMyleadsExist ? 0
        : search.projectsForCompanyId.findIndex(obj => obj.objectId === ID),
      list: props.prevNextList === 'searchList' ? [popup.properties]
        : props.prevNextList === 'selectedSearchList' ? [popup.properties]
          : props.prevNextList === 'projectHierarchicalSearchList' || ifMyleadsHierarchyExist ? [popup.properties]
            : props.prevNextList === 'companyHierarchicalSearchList' ? search.projectsForCompanyId
              : props.prevNextList === 'groupedCompanyProjects' ? [popup.properties]
                : ifMyleadsExist ? [popup.properties] : [],
      activeTab: props.prevNextList === 'myLeadsUpdates' ? '3' : '1',
      previousState: location.state,
      from: 'mapClick'
    }
    navigate(`/app/project/${ID}`, { state: routingState });
  }
}

export const filterID = (id, getLeaves, propertyName) => {
  if (!id) return false
  var data = { leave: getLeaves() }
  var filterData;
  data.leave.filter(function (e, key) {
    if (!propertyName) {
      propertyName = "objectId"
    }
    if (id == e.props['data-feature'].properties[propertyName]) {
      filterData = true
    }
  })
  return filterData;
}