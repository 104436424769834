import { createSlice } from "@reduxjs/toolkit"
import _, { cloneDeep } from "lodash"
import objectAssign from 'object-assign'
import { clearAllRolesBeforeSettingFavouriteRoles, MEASURE_FILTER_KEYS, STATE_KEYS, updateActiveStatusOnFavouriteRoles, updateActiveStatusOnParentAndChildren, updateMaterialFilterActiveState, updateMaterialInput } from "../utils"

const initialState = {
    filtersState: {
        project: {
            display: 'project'
        },
        company: {
            display: 'company'
        },
        people: {
            display: 'people'
        }
    },
    selectedSearchType: 'project',
    criteria: {
        offset: 0,
        records: 50
    },
    defaultFilter: {
        project: {
            display: 'project'
        },
        company: {
            display: 'company'
        },
        people: {
            display: 'people'
        }
    },
    isFilterActive: false,
    onNoFilterOption: false,
    searchMode: 'basic',
    profileStore: false,
    newSearchClick: false,
    searchHere: false,
    handleView:false
}

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        updateFilterState: (state, action) => {
            let newFilterState = {}
            newFilterState['project'] = action.payload
            newFilterState['company'] = action.payload
            newFilterState['people'] = action.payload
            let updatedDefaultFilter = {
                ...newFilterState,
                project: {
                    ...newFilterState.project,
                    display: 'project',
                    sort: '-project_last_published'
                },
                company: {
                    ...newFilterState.company,
                    display: 'company',
                    sort: 'company_name'
                },
                people: {
                    ...newFilterState.people,
                    display: 'people',
                    sort: 'person_first_name,person_last_name'
                }
            }
            let stateObj = {
                ...state,
                defaultFilter: updatedDefaultFilter,
                filtersState: updatedDefaultFilter
            }
            return objectAssign({}, state, stateObj)
        },
        updateFilterText: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            if (action.payload.keyname !== 'projectInformation') {
                newFilterState = {
                    ...newFilterState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [action.payload.keyname]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.keyname],
                            value: action.payload.value,
                            dataObj: action.payload.dataObj,
                            active: action.payload.active
                        },
                        sort: ''
                    }
                }
            } else {
                newFilterState = {
                    ...newFilterState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [action.payload.keyname]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.keyname],
                            value: action.payload.value,
                            dataObj: action.payload.dataObj,
                            active: action.payload.active,
                            isAnyAll: action.payload.isAnyAll
                        },
                        sort: ''
                    }
                }
            }

            state.filtersState = newFilterState
            return state
        },
        updateDefaultFilterState: (state, action) => {
            state.defaultFilter = action.payload
            return state
        },
        resetFilterStateWithDefaultFilters: (state, action) => {
            state.filtersState = action.payload
            return state
        },
        updatePreviousSelectedValue: (state, action) => {
            state.previousSelectedValue = action.payload
            return state
        },
        updateDisplayKey: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    [action.payload['key']]: {
                        ...state.filtersState[state.selectedSearchType][action.payload['key']],
                        value: action.payload.text,
                        active: action.payload.isPreservePastActivestate ? state.filtersState[state.selectedSearchType][action.payload['key']].active : action.payload.text && action.payload.text != "" ? true : false,
                    }
                    // sort: payload.sort ? payload.sort : state.filtersState[state.selectedSearchType].sort
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateAdvanceLocation: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    [action.payload.keyname]: {
                        ...state.filtersState[state.selectedSearchType][action.payload.keyname],
                        location: action.payload.location,
                        latitude: action.payload.latitude,
                        longitude: action.payload.longitude,
                        radius: action.payload.radius,
                        active: action.payload.active
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateProjectFramework: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    [action.payload.keyname]: {
                        value: action.payload.value,
                        dataObj: action.payload.dataObj,
                        active: action.payload.active
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateShowCompletedChecked: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    completedProject: {
                        ...state.filtersState[state.selectedSearchType].completedProject,
                        active: action.payload
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateAppliedCriteria: (state, action) => {
            state.criteria = action.payload
            return state
        },
        toggleBuildPhaseFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            let buildPhaseOptions = Object.assign([], state.filtersState[state.selectedSearchType].buildPhase.options)
            if (action.payload) {
                buildPhaseOptions = buildPhaseOptions.map(item => item.value === "no_timing" ? { ...item, active: false } : item)
            } else {
                buildPhaseOptions = buildPhaseOptions.map(item => { return { ...item, active: true } })
            }
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    buildPhase: {
                        ...state.filtersState[state.selectedSearchType].buildPhase,
                        active: action.payload,
                        options: buildPhaseOptions
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateBuildPhaseValueReducer: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    buildPhase: {
                        ...state.filtersState[state.selectedSearchType].buildPhase,
                        sliderValue: action.payload.sliderValue,
                        options: state.filtersState[state.selectedSearchType].buildPhase.options.map(
                            item => action.payload.activePhase.includes(item.value) ? { ...item, active: true } :
                                { ...item, active: false })
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateMeasures: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    measure: {
                        ...state.filtersState[state.selectedSearchType].measure,
                        active: action.payload[Object.keys(action.payload)[0]].active,
                        options: {
                            ...state.filtersState[state.selectedSearchType].measure.options,
                            [Object.keys(action.payload)[0]]: {
                                from: action.payload[Object.keys(action.payload)[0]].from,
                                to: action.payload[Object.keys(action.payload)[0]].to,
                                active: action.payload[Object.keys(action.payload)[0]].active

                            }
                        }
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        resetObjectToDefaultAtKey: (state, action) => {
            // Handling of special cases - Measures

            let newObjectAtKey = cloneDeep(state.defaultFilter[state.selectedSearchType][action.payload.key])
            let newFilterState = cloneDeep(state.filtersState)
            if (MEASURE_FILTER_KEYS.indexOf(action.payload.key) > -1) {
                newObjectAtKey = cloneDeep(state.defaultFilter[state.selectedSearchType].measure.options[action.payload.key])
                newFilterState = {
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        measure: {
                            ...state.filtersState[state.selectedSearchType].measure,
                            options: {
                                ...state.filtersState[state.selectedSearchType].measure.options,
                                [action.payload.key]: newObjectAtKey
                            }
                        }
                    }
                }
            }
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    [action.payload.key]: newObjectAtKey
                }
            }
            state.filtersState = newFilterState
            return state
        },
        toggleFilterActiveState: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)

            // Handling of special cases - Measures
            if (MEASURE_FILTER_KEYS.includes(action.payload.key)) {

                // if any of the measure filters are active ( other than the current selected filter and the action.payload.value is not true) 
                // then make measures active
                let isMeasuresActive = action.payload.value ? action.payload.value : false
                let measureFilters = state.filtersState[state.selectedSearchType][STATE_KEYS.MEASURE].options
                let measureFilterKeys = Object.keys(measureFilters)
                for (let i = 0; i < measureFilterKeys.length; i++) {
                    if (measureFilters[measureFilterKeys[i]].active && measureFilterKeys[i] !== action.payload.key) {
                        isMeasuresActive = true
                    }
                }
                newFilterState = {
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [STATE_KEYS.MEASURE]: {
                            ...state.filtersState[state.selectedSearchType][STATE_KEYS.MEASURE],
                            active: isMeasuresActive,
                            options: {
                                ...state.filtersState[state.selectedSearchType][STATE_KEYS.MEASURE].options,
                                [action.payload.key]: {
                                    ...state.filtersState[state.selectedSearchType][STATE_KEYS.MEASURE].options[action.payload.key],
                                    active: action.payload.value
                                }
                            }
                        }
                    }
                }
            }
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: action.payload.key === 'inclusions' ? {
                    ...state.filtersState[state.selectedSearchType],
                    excludeStages: {
                        ...state.filtersState[state.selectedSearchType][action.payload.key],
                        active: !action.payload.value,
                        toggleActive: action.payload.value
                    }
                } : {
                    ...state.filtersState[state.selectedSearchType],
                    [action.payload.key]: {
                        ...state.filtersState[state.selectedSearchType][action.payload.key],
                        active: action.payload.value
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateTextInputFilter: (state, action) => {
            if (!action.payload.filter) {
                return state
            }
            if (action.payload.isIncludeExclude) {
                // both include and exclude lists need to be filtered in sync
                let mainList = {}
                let secondaryList = {}
                let filteredMainList = []
                let filteredSecondaryList = []
                mainList = state.filtersState[state.selectedSearchType][action.payload.includeKey]
                secondaryList = state.filtersState[state.selectedSearchType][action.payload.excludeKey]
                if (mainList && secondaryList) {
                    mainList?.options.map((option, index) => {
                        let secondaryOption = secondaryList.options[index]
                        if (option.name.toLowerCase().indexOf(action.payload.text.toLowerCase()) !== -1 || !action.payload.text) {
                            option.display = true
                            secondaryOption.display = true
                        } else {
                            option.display = false
                            secondaryOption.display = false
                        }
                        if (action.payload.child != null) {
                            let updatedChildren = []
                            let updatedSecondaryChildren = []
                            if (secondaryOption[action.payload.child] && secondaryOption[action.payload.child].length > 0) {
                                option[action.payload.child].map(function (item, i) {
                                    let secondarySubItem = secondaryOption[action.payload.child][i]
                                    if (item.name.toLowerCase().indexOf(action.payload.text.toLowerCase()) !== -1 || !action.payload.text) {
                                        option.display = true
                                        item.display = true
                                        secondaryOption.display = true
                                        secondarySubItem.display = secondarySubItem ? true : ''
                                    } else {
                                        item.display = false
                                        secondarySubItem.display = secondarySubItem ? false : ''
                                    }
                                    updatedChildren.push(item)
                                    secondarySubItem = secondarySubItem ? updatedSecondaryChildren?.push(secondarySubItem) : ''
                                })
                            } else {
                                option[action.payload.child].map(function (item, i) {
                                    if (item.name.toLowerCase().indexOf(action.payload.text.toLowerCase()) !== -1 || !action.payload.text) {
                                        option.display = true
                                        item.display = true
                                    } else {
                                        item.display = false
                                    }
                                    updatedChildren.push(item)
                                })
                            }
                            option[action.payload.child] = updatedChildren
                            secondaryOption[action.payload.child] = updatedSecondaryChildren
                        }
                        filteredMainList.push(option)
                        filteredSecondaryList.push(secondaryOption)
                    })
                }
                let newFilterState = state.filtersState
                newFilterState = {
                    ...newFilterState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [action.payload.includeKey]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.includeKey],
                            options: filteredMainList
                        },
                        [action.payload.excludeKey]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.excludeKey],
                            options: filteredSecondaryList
                        }
                    }
                }
                state.filtersState = newFilterState
                return state
            } else {

                let filter = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.filter]))
                let filteredList = []
                if (action.payload.text) {
                    filter.options.map(function (option) {
                        if (option.name.toLowerCase().indexOf(action.payload.text.toLowerCase()) !== -1) {
                            option.display = true
                        } else {
                            option.display = false
                        }
                        if (action.payload.child != null) {
                            let updatedChildren = []
                            option[action.payload.child].map(function (item) {
                                if (item.name.toLowerCase().indexOf(action.payload.text.toLowerCase()) !== -1) {
                                    option.display = true
                                    item.display = true
                                } else {
                                    item.display = false
                                }
                                if (action.payload.subChild) {
                                    let updatedSubChild = []
                                    item[action.payload.subChild].map(element => {
                                        if (element.name.toLowerCase().indexOf(action.payload.text.toLowerCase()) !== -1) {
                                            element.display = true
                                            option.display = true
                                            item.display = true
                                        } else {
                                            element.display = false
                                        }
                                        updatedSubChild.push(element)
                                    })
                                    item[action.payload.subChild] = updatedSubChild
                                }
                                updatedChildren.push(item)
                            })
                            option[action.payload.child] = updatedChildren
                        }
                        filteredList.push(option)
                    })
                } else {
                    filter.options.map(function (option) {
                        option.display = true
                        if (action.payload.child != null) {
                            let updatedChildren = []
                            option[action.payload.child].map(function (item) {
                                item.display = true
                                updatedChildren.push(item)
                                if (action.payload.subChild) {
                                    let updatedSubChild = []
                                    item[action.payload.subChild].map(element => {
                                        element.display = true
                                        updatedSubChild.push(element)
                                    })
                                    item[action.payload.subChild] = updatedSubChild
                                }
                            })
                            option[action.payload.child] = updatedChildren
                        }
                        filteredList.push(option)
                    })
                }
                let newFilterState = cloneDeep(state.filtersState)
                newFilterState = {
                    ...newFilterState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [action.payload.filter]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.filter],
                            options: filteredList
                        }
                    }
                }
                state.filtersState = newFilterState
                return state
            }
        },
        updateFilterItems: (state, action) => {
            if (!action?.payload?.filter) {
                return state
            }
            let mainList = {}
            let secondaryList = {}
            if (action.payload.isIncludeExclude) {
                let newFilterState = cloneDeep(state.filtersState)
                if (action.payload.isExcluded) {
                    mainList = Object.assign([], state.filtersState[state.selectedSearchType][action.payload.excludeKey])
                    secondaryList = Object.assign([], state.filtersState[state.selectedSearchType][action.payload.includeKey])
                } else {
                    mainList = Object.assign([], state.filtersState[state.selectedSearchType][action.payload.includeKey])
                    secondaryList = Object.assign([], state.filtersState[state.selectedSearchType][action.payload.excludeKey])
                }
                // 1st run through the main list and make the item active/inactive
                // then run through the secondary list and make the same item opposite of mainList's to keep in sync
                const filteredMainList = updateActiveStatusOnParentAndChildren(mainList.options, action.payload.active, action.payload)
                const filteredSecondaryList = updateActiveStatusOnParentAndChildren(secondaryList.options, false, action.payload)
                let mainListActive, secondaryActive
                if (action.payload.child) {
                    mainListActive = filteredMainList.some(item => item.active || item.isChildSelected)
                    secondaryActive = filteredSecondaryList.some(item => item.active || item.isChildSelected)
                } else {
                    mainListActive = filteredMainList.some(item => item.active)
                    secondaryActive = filteredSecondaryList.some(item => item.active)
                }
                newFilterState = {
                    ...newFilterState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [action.payload.includeKey]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.includeKey],
                            active: action.payload.isExcluded ? secondaryActive : mainListActive,
                            isAllSelected: action.payload.isExcluded ? secondaryActive : mainListActive,
                            options: action.payload.isExcluded ? filteredSecondaryList : filteredMainList
                        },
                        [action.payload.excludeKey]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.excludeKey],
                            active: action.payload.isExcluded ? mainListActive : secondaryActive,
                            isAllSelected: action.payload.isExcluded ? mainListActive : secondaryActive,
                            options: action.payload.isExcluded ? filteredMainList : filteredSecondaryList
                        }
                    }
                }
                state.filtersState = newFilterState
                return state
            } else {
                let newFilterState = cloneDeep(state.filtersState)
                let filter = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.filter]))
                let filteredList = []
                filter.options.map(function (option) {
                    if (option.value == action.payload.value && option.display) {
                        option.active = action.payload.active
                    }
                    if (action.payload.child != null) {
                        let updatedChildren = []
                        let activeChilds = []
                        option[action.payload.child].map(function (item) {
                            if (!action.payload.isChild && action?.payload?.selectedData?.length > 0) {
                                if (option.value == action.payload.value && action.payload.selectedData.includes(item.value)) {
                                    if (option.active && item.subscribed) {
                                        item.active = true
                                    } else {
                                        item.active = false
                                    }
                                }
                            } 
                            else if (!action.payload.isChild) {
                                if (option.value == action.payload.value) {
                                    if (option.active && item.subscribed) {
                                        item.active = true
                                    } else {
                                        item.active = false
                                    }
                                }
                            } 
                            else {
                                if (option.value == action.payload.parentIdForChild && item.value == action.payload.value && item.display) {
                                    item.active = action.payload.active
                                }
                            }
                            activeChilds.push(item.active)
                            updatedChildren.push(item)
                        })
                        if (updatedChildren.every(element => element.active === true) && option.display) {
                            option.active = true
                            option.isChildSelected = false
                        } else if (updatedChildren.some(element => element.active === true)) {
                            option.isChildSelected = true
                            option.active = false
                        } else {
                            option.active = false
                            option.isChildSelected = false
                        }
                        option[action.payload.child] = updatedChildren
                    }
                    filteredList.push(option)
                })
                newFilterState = {
                    ...newFilterState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [action.payload.filter]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.filter],
                            options: filteredList,
                            active: filteredList.some(item => item.active || item.isChildSelected),
                            isAllSelected: filteredList.every(item => item.active)
                        }
                    }
                }
                state.filtersState = newFilterState
                return state
            }
        },
        updateMaterialFilterItem: (state, action) => {
            let updatedActive = { isActive: false }
            const updatedOptions = updateMaterialInput(action.payload.value, action.payload.active, state.filtersState[state.selectedSearchType].materials.options, updatedActive,action.payload.selectedData)
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    materials: {
                        ...state.filtersState[state.selectedSearchType].materials,
                        active: updatedActive.isActive,
                        options: updatedOptions,
                        isAllSelected:action.payload.type!=undefined && action.payload.type ==="includeSelectAll" ? true : updatedOptions.every(item => item.active)
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateValue: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    value: {
                        from: action.payload.from,
                        to: action.payload.to,
                        displayFrom: action.payload.displayFrom,
                        displayTo: action.payload.displayTo,
                        active: action.payload.active
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateStartConstructionFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    startConstruction: {
                        ...state.filtersState[state.selectedSearchType].startConstruction,
                        options: state.filtersState[state.selectedSearchType].startConstruction.options.map(record => action.payload.includes(record.name) ? {
                            ...record,
                            active: true
                        } : {
                            ...record,
                            active: false
                        })
                    }
                }
            }
            state.filtersState = newFilterState
            return state

        },
        updateEndConstructionFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    endConstruction: {
                        ...state.filtersState[state.selectedSearchType].endConstruction,
                        options: state.filtersState[state.selectedSearchType].endConstruction.options.map(record => action.payload.includes(record.name) ? {
                            ...record,
                            active: true
                        } : {
                            ...record,
                            active: false
                        })
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        toggleStartConstructionFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    startConstruction: {
                        ...state.filtersState[state.selectedSearchType].startConstruction,
                        active: action.payload,
                        options: state.filtersState[state.selectedSearchType].startConstruction.options.map(item =>
                            item.name === "TODAY" || item.name === "1M" ? { ...item, active: true } : { ...item, active: false })
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        toggleEndConstructionFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    endConstruction: {
                        ...state.filtersState[state.selectedSearchType].endConstruction,
                        active: action.payload,
                        options: state.filtersState[state.selectedSearchType].endConstruction.options.map(
                            item => item.name === "TODAY" || item.name === "1M" ? { ...item, active: true } : { ...item, active: false })
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updatePrimaryCategoriesOnlyFlag: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    primaryCategoriesOnly: action.payload
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateCategoryUsageTypeFilter: (state, action) => {
            let currentProjectUsageValue = cloneDeep(state.filtersState[state.selectedSearchType]?.projectUsage?.value)
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    projectUsage: {
                        ...state.filtersState[state.selectedSearchType].projectUsage,
                        active: action.payload?.isSelected,
                        value: action.payload?.isSelected && !currentProjectUsageValue?.includes(action.payload?.value) ?
                            [...currentProjectUsageValue, action.payload?.value] :
                            !action.payload?.isSelected && action.payload?.value && currentProjectUsageValue?.includes(action.payload?.value) ?
                                currentProjectUsageValue?.filter(item => item !== action.payload?.value) :
                                currentProjectUsageValue
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateCalenderStartFrom: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    calendarStartDate: {
                        ...state.filtersState[state.selectedSearchType].calendarStartDate,
                        from: action.payload
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateCalenderStartTo: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    calendarStartDate: {
                        ...state.filtersState[state.selectedSearchType].calendarStartDate,
                        to: action.payload
                    }

                }
            }
            state.filtersState = newFilterState
            return state
        },
        activateCalenderTimings: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    calendarStartDate: {
                        ...state.filtersState[state.selectedSearchType].calendarStartDate,
                        active: action.payload,
                        from: "",
                        to: ""
                    },
                    calendarEndDate: {
                        ...state.filtersState[state.selectedSearchType].calendarEndDate,
                        active: action.payload,
                        from: "",
                        to: ""
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateCalenderEndFrom: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    calendarEndDate: {
                        ...state.filtersState[state.selectedSearchType].calendarEndDate,
                        from: action.payload
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateCalenderEndTo: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    calendarEndDate: {
                        ...state.filtersState[state.selectedSearchType].calendarEndDate,
                        to: action.payload
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateLastUpdatedFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    updated: {
                        ...state.filtersState[state.selectedSearchType].updated,
                        options: state.filtersState[state.selectedSearchType].updated.options.map(record => record.name === action.payload ? {
                            ...record,
                            active: true
                        } : {
                            ...record,
                            active: false
                        })
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateNewPublishFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    new: {
                        ...state.filtersState[state.selectedSearchType].new,
                        options: state.filtersState[state.selectedSearchType].new.options.map(record => record.name === action.payload ? {
                            ...record,
                            active: true
                        } : {
                            ...record,
                            active: false
                        })
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        toggleCalendarTimingFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    calendarStartDate: {
                        ...state.filtersState[state.selectedSearchType].calendarStartDate,
                        active: action.payload,
                        from: action.payload ? state.filtersState[state.selectedSearchType].calendarStartDate.from : "",
                        to: action.payload ? state.filtersState[state.selectedSearchType].calendarStartDate.to : ""
                    },
                    calendarEndDate: {
                        ...state.filtersState[state.selectedSearchType].calendarEndDate,
                        active: action.payload,
                        from: action.payload ? state.filtersState[state.selectedSearchType].calendarEndDate.from : "",
                        to: action.payload ? state.filtersState[state.selectedSearchType].calendarEndDate.to : ""
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateActivateFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: action.payload
            }
            state.filtersState = newFilterState
            return state
        },

        toggleLastUpdatedFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    updated: {
                        ...state.filtersState[state.selectedSearchType].updated,
                        toggleActive: action.payload
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        toggleNewUpdatedFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    new: {
                        ...state.filtersState[state.selectedSearchType].new,
                        toggleActive: action.payload
                    }
                }

            }
            state.filtersState = newFilterState
            return state
        },
        updateSelectAllOptions: (state, action) => {
            if (!action?.payload?.filter) {
                return state
            }
            if (action.payload.isIncludeExclude) {
                let mainList = {}
                let secondaryList = {}
                if (action.payload.isExcluded) {
                    mainList = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.excludeKey]))
                    secondaryList = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.includeKey]))
                } else {
                    mainList = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.includeKey]))
                    secondaryList = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.excludeKey]))
                }
                // 1st run through the main list and make the item active/inactive
                // then run through the secondary list and make the same item opposite of mainList's to keep in sync
                const filteredMainList = updateActiveStatusOnParentAndChildren(mainList.options, true, action.payload)
                const filteredSecondaryList = updateActiveStatusOnParentAndChildren(secondaryList.options, false, action.payload)
                let newFilterState = cloneDeep(state.filtersState)
                newFilterState = {
                    ...newFilterState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [action.payload.includeKey]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.includeKey],
                            isAllSelected: !action.payload.isExcluded,
                            options: action.payload.isExcluded ? filteredSecondaryList : filteredMainList,
                            active: !action.payload.isExcluded
                        },
                        [action.payload.excludeKey]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.excludeKey],
                            isAllSelected: action.payload.isExcluded,
                            options: action.payload.isExcluded ? filteredMainList : filteredSecondaryList,
                            active: action.payload.isExcluded
                        }
                    }
                }
                state.filtersState = newFilterState
                return state
            } else {
                let filter = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.filter]))
                let filteredList = []
                filter.options.map(function (option) {
                    if (action?.payload?.filter !== "regions" || action?.payload?.filter !== "companyRegions") {
                    if (option.display && option.subscribed) {
                        option.active = true
                    } else {
                        option.active = false
                    }
                    }
                    if (action.payload.child != null) {
                        let updatedChildren = []
                        if(option.display && option[action.payload.child].every(child => !child.display)){
                            option[action.payload.child].map(item => {
                                item.active =true
                                updatedChildren.push(item)
                            })
                        
                        }
                        else{
                            option[action.payload.child].map(function (item) {
                                if(action?.payload?.selectedData?.length>0){
                                    if ((option.display && option.subscribed) && (item.display && item.subscribed) &&  action.payload.selectedData.includes(item.value) ) {
                                        item.active = true
                                    } else {
                                        item.active = false
                                    }
                                }
                                else{
                                    if ((option.display && option.subscribed) && (item.display && item.subscribed)) {
                                        item.active = true
                                    } else {
                                        item.active = false
                                    } 
                                }
                               
                                updatedChildren.push(item)
                            })
                        }
                    
                        option[action.payload.child] = updatedChildren

                        if (action.payload.child === "counties" && action?.payload?.filter === "regions" || action?.payload?.filter === "companyRegions") {
                            if (option.display && option.subscribed && option[action.payload.child].length > 0 && option[action.payload.child].every(county => county.active)) {
                                option.active = true
                            }
                            else {
                                option.active = false
                            }
                        }

                    }
                    option['isChildSelected'] = option[action.payload.child].length > option[action.payload.child].filter(subItem => subItem.active).length && option[action.payload.child].filter(subItem => subItem.active).length !== 0 ? true : false;
                    filteredList.push(option)
                })
                let newFilterState = cloneDeep(state.filtersState)
                newFilterState = {
                    ...newFilterState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [action.payload.filter]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.filter],
                            isAllSelected: true,
                            options: filteredList,
                            active: true
                        }
                    }
                }
                state.filtersState = newFilterState
                return state
            }
        },
        updateClearAllOptions: (state, action) => {
            if (!action?.payload?.filter) {
                return state
            }
            if (action.payload.isIncludeExclude) {
                let mainList = {}
                if (action.payload.isExcluded) {
                    mainList = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.excludeKey]))
                } else {
                    mainList = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.includeKey]))
                }
                // 1st run through the main list and make the item inactive
                const filteredMainList = updateActiveStatusOnParentAndChildren(mainList.options, false, action.payload)
                let newFilterState = cloneDeep(state.filtersState)
                newFilterState = {
                    ...newFilterState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [action.payload.includeKey]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.includeKey],
                            isAllSelected: !action.payload.isExcluded ? false : state.filtersState[state.selectedSearchType][action.payload.includeKey].isAllSelected,
                            options: action.payload.isExcluded ? state.filtersState[state.selectedSearchType][action.payload.includeKey].options : filteredMainList,
                            active: action.payload.isExcluded ? state.filtersState[state.selectedSearchType][action.payload.includeKey].active : false
                        },
                        [action.payload.excludeKey]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.excludeKey],
                            isAllSelected: action.payload.isExcluded ? false : state.filtersState[state.selectedSearchType][action.payload.excludeKey].isAllSelected,
                            options: action.payload.isExcluded ? filteredMainList : state.filtersState[state.selectedSearchType][action.payload.excludeKey].options,
                            active: action.payload.isExcluded ? false : state.filtersState[state.selectedSearchType][action.payload.excludeKey].active
                        }
                    }
                }
                state.filtersState = newFilterState
                return state
            } else {
                let filter = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.filter]))
                let filteredList = []
                filter.options.map(function (option) {
                    option.active = false
                    if (action.payload.child != null) {
                        let updatedChildren = []
                        option[action.payload.child].map(function (item) {
                            item.active = false
                            updatedChildren.push(item)
                        })
                        option[action.payload.child] = updatedChildren
                    }
                    option.isChildSelected = false
                    filteredList.push(option)
                })
                let newFilterState = cloneDeep(state.filtersState)
                newFilterState = {
                    ...newFilterState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [action.payload.filter]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.filter],
                            isAllSelected: false,
                            options: filteredList,
                            active: false
                        }
                    }
                }
                state.filtersState = newFilterState
                return state
            }
        },
        updateCategoryLandTypeFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            let currentProjectLandValue = cloneDeep(state.filtersState[state.selectedSearchType].projectLandType.value)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    projectLandType: {
                        ...state.filtersState[state.selectedSearchType].projectLandType,
                        value: action.payload.isSelected && !currentProjectLandValue.includes(action.payload.value) ?
                            [...currentProjectLandValue, action.payload.value] :
                            !action.payload.isSelected && action.payload.value && currentProjectLandValue.includes(action.payload.value) ?
                                currentProjectLandValue.filter(item => item !== action.payload.value) :
                                currentProjectLandValue
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        toggleAnyAllVal: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    projectInformation: {
                        ...state.filtersState[state.selectedSearchType].projectInformation,
                        isAnyAll: action.payload
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        toggleRoleAddedFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    roleAdded: {
                        ...state.filtersState[state.selectedSearchType].roleAdded,
                        toggleActive: action.payload
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateRoleAddedFilter: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    roleAdded: {
                        ...state.filtersState[state.selectedSearchType].roleAdded,
                        options: state.filtersState[state.selectedSearchType].roleAdded.options.map(record => record.name === action.payload ? {
                            ...record,
                            active: true
                        } : {
                            ...record,
                            active: false
                        })
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateFilterActive: (state, action) => {
            state.isFilterActive = action.payload
            return state;
        },
        updateIncludeExcludeCheckbox: (state, action) => {
            state.includeExcludeChecked = action.payload
            return state
        },
        updateSelectedSearchType: (state, action) => {
            state.selectedSearchType = action.payload
            return state
        },
        updateOnNoFilterOption: (state, action) => {
            state.onNoFilterOption = action.payload
            return state
        },
        updateContextType: (state, action) => {
            state.selectedSearchType = action.payload.context
            state.searchMode = action.payload.searchMode !== "" ? action.payload.searchMode : state.searchMode
            return state
        },
        updateContextFilter: (state, action) => {
            let newFilterState = _.cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [action.payload.context]: {
                    ...state.filtersState[action.payload.context],
                    ...action.payload.filter
                }
            }
            state.filtersState = newFilterState
            return state
        },
        resetFilterState: (state, action) => {
            state.filtersState = action.payload
            state.defaultFilter = action.payload
            return state
        },
        updateProfileStore: (state, action) => {
            state.profileStore = action.payload
            return state
        },
        updateSort: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    sort: action.payload
                }
            }
            state.filtersState = newFilterState
            return state
        },
        toggleFuzzyExact: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            newFilterState = {
                ...newFilterState,
                [state.selectedSearchType]: {
                    ...state.filtersState[state.selectedSearchType],
                    brands: {
                        ...state.filtersState[state.selectedSearchType].brands,
                        isFuzzyExact: action.payload
                    }
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateOffsetLimit: (state, action) => {
            let newFilterState = cloneDeep(state.filtersState)
            let type = action.payload.type ? action.payload.type : state.selectedSearchType
            newFilterState = {
                ...newFilterState,
                [type]: {
                    ...state.filtersState[type],
                    offset: action.payload.offset,
                    records: action.payload.records
                }
            }
            state.filtersState = newFilterState
            return state
        },
        updateNewSearch: (state, action) => {
            state.newSearchClick = action.payload
        },
        selectAllMaterialFilter: (state, action) => {
            return {
                ...state,
                filtersState: {
                    ...state.filtersState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        materials: {
                            ...state.filtersState[state.selectedSearchType].materials,
                            active: action.payload,
                            options: updateMaterialFilterActiveState(action.payload, state.filtersState[state.selectedSearchType].materials.options),
                            isAllSelected: action.payload
                        }
                    }
                }
            }
        },
        clearAllMaterialFilter: (state, action) => {
            return {
                ...state,
                filtersState: {
                    ...state.filtersState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        materials: {
                            ...state.filtersState[state.selectedSearchType].materials,
                            active: action.payload,
                            options: updateMaterialFilterActiveState(action.payload, state.filtersState[state.selectedSearchType].materials.options),
                            isAllSelected: action.payload
                        }
                    }
                }
            }
        },
        updateSearchHere: (state, action) => {
            state.searchHere = action.payload
        },
        updateSavedSearchCheckboxFilters: (state, action) => {
            let payload = action.payload
            return {
                ...state,
                filtersState: {
                    ...state.filtersState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [payload.title]: {
                            ...state.filtersState[state.selectedSearchType][payload.title],
                            active: payload.value.active,
                            options: payload.value.options,
                            isAllSelected: payload.value.isAllSelected
                        }
                    }
                }
            }
        },
        updateExcludeStageFilter: (state, action) => {
            return {
                ...state,
                filtersState: {
                    ...state.filtersState,
                    [state.selectedSearchType]: {
                        ...state.filtersState[state.selectedSearchType],
                        [action.payload.name]: {
                            ...state.filtersState[state.selectedSearchType][action.payload.name],
                            active: action.payload.active,
                            toggleActive: action.payload.active
                        }
                    }
                }
            }
        },
        updateSelectAllFavourites: (state, action) => {
            if (!action.payload.filter) {
                return {
                    ...state
                }
            }
            if (action.payload.isIncludeExclude) {
                let mainList = {}
                let secondaryList = {}
                if (action.payload.isExcluded) {
                    mainList = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.excludeKey]))
                    secondaryList = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.includeKey]))
                } else {
                    mainList = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.includeKey]))
                    secondaryList = JSON.parse(JSON.stringify(state.filtersState[state.selectedSearchType][action.payload.excludeKey]))
                }

                // 1st run through the main list and make the item active/inactive
                // then run through the secondary list and make the same item opposite of mainList's to keep in sync
                const ClearAllMainList = clearAllRolesBeforeSettingFavouriteRoles(mainList)
                const filteredMainList = updateActiveStatusOnFavouriteRoles(ClearAllMainList.options, true, action.payload.favourites)
                const filteredSecondaryList = updateActiveStatusOnFavouriteRoles(secondaryList.options, false, action.payload.favourites)

                return {
                    ...state,
                    filtersState: {
                        ...state.filtersState,
                        [state.selectedSearchType]: {
                            ...state.filtersState[state.selectedSearchType],
                            [action.payload.includeKey]: {
                                ...state.filtersState[state.selectedSearchType][action.payload.includeKey],
                                active: action.payload.isExcluded ? state.filtersState[state.selectedSearchType][action.payload.includeKey].active : true,
                                isAllSelected: !action.payload.isExcluded,
                                options: action.payload.isExcluded ? filteredSecondaryList : filteredMainList
                            },
                            [action.payload.excludeKey]: {
                                ...state.filtersState[state.selectedSearchType][action.payload.excludeKey],
                                active: action.payload.isExcluded ? true : state.filtersState[state.selectedSearchType][action.payload.excludeKey].active,
                                isAllSelected: action.payload.isExcluded,
                                options: action.payload.isExcluded ? filteredMainList : filteredSecondaryList
                            }
                        }
                    }
                }
            }
        },
        updateHandleViewForShowCompanyShowProjects:(state,action)=>{
            state.handleView = action.payload

        }
    }
})

export const { updateFilterState, updateFilterText, updatePreviousSelectedValue, updateDisplayKey, updateAdvanceLocation,
    updateProjectFramework, updateShowCompletedChecked, updateAppliedCriteria, toggleBuildPhaseFilter, updateBuildPhaseValueReducer,
    toggleFilterActiveState, resetObjectToDefaultAtKey, updateMeasures, updateProjectCategories, updateProjectGeocodes,
    updateProjectStages, updateCompanyGeocodes, updateProjectDevelopmentTypes, updateProjectUsage, updateProjectRoles, updateProjectMaterials,
    updateProjectLandType, updateTextInputFilter, updateFilterItems, updateMaterialFilterItem, updateValue, updateStartConstructionFilter,
    updateEndConstructionFilter, toggleStartConstructionFilter, toggleEndConstructionFilter, updatePrimaryCategoriesOnlyFlag, updateCategoryUsageTypeFilter,
    toggleCalendarTimingFilter, updateCalenderEndTo, updateCalenderEndFrom, activateCalenderTimings, updateCalenderStartTo,
    updateCalenderStartFrom, updateNewPublishFilter, updateLastUpdatedFilter, updateActivateFilter, toggleLastUpdatedFilter, toggleNewUpdatedFilter,
    updateCategoryLandTypeFilter, updateSelectAllOptions, updateClearAllOptions, toggleAnyAllVal, toggleRoleAddedFilter, updateRoleAddedFilter,
    updateIncludeExcludeCheckbox, updateFilterActive, updateSelectedSearchType, updateOnNoFilterOption, updateDefaultFilterState, resetFilterStateWithDefaultFilters,
    updateContextType, updateContextFilter, resetFilterState, updateProfileStore, updateSort, toggleFuzzyExact, updateOffsetLimit,
    updateNewSearch, selectAllMaterialFilter, clearAllMaterialFilter, updateSearchHere, updateSavedSearchCheckboxFilters,
    updateExcludeStageFilter, updateSelectAllFavourites ,updateHandleViewForShowCompanyShowProjects } = filterSlice.actions

export const filterReducer = filterSlice.reducer